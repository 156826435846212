<template>
    <div>
      <base-rate-form
        :moduleView="sectionList.classBaseRate"
        :isCreate="false"
      />
    </div>
  </template>
  
  <script>
  import BaseRateForm from "@/views/baserate/BaseRateForm.vue";
  import { sectionList } from "@/helpers/utility";
  
  export default {
    bodyClass: "landing",
    components: {
      BaseRateForm,
    },
    data: function() {
      return {
        sectionList: sectionList,
      };
    },
  };
  </script>
  
  <style></style>
  