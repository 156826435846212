<template>
  <div>
    <div class="create-section-text mb-2">
      Choose a currency type
    </div>

    <div class="field-dropdown-main mb-4 price-method-dropdwon">
      <choices-single 
        v-model="selectedCurrency"
        id="currencyListDropdown"
        :options="currencyList"
        class="currency-dropdown"
      >
        <option value="0">Select Currency</option>
      </choices-single>
    </div> 
  </div>
</template>
  
  <script>
  import ChoicesSingle from "@/components/SingleSelect";
  import { currencyList } from "@/helpers/utility";
  import { mapGetters } from "vuex";
  
  export default {
    bodyClass: "landing",
    components: {
      ChoicesSingle,
    },
    data: function() {
      return {
      currencyList: currencyList,
      currencyDefault: "USD"
      }
    },
    computed: {
      ...mapGetters(['currencyType']),
      selectedCurrency: {
        get() {
          return this.currencyType;
        },
        set(value) {
          this.currencyDefault = value
          this.$emit('currency-default-changed', value);
        }
      }
    }
  }
  </script>
  
  <style></style>