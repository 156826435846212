var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"page-header home-page-card"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-lg-4 col-md-8 mx-auto"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body py-lg-5"},[_c('div',[_c('div',{staticClass:"text-center text-muted-bold mb-3"},[_vm._v(" Build and view the sections used to create your Tariff ")]),_c('div',{staticClass:"text-center mb-3"},[_c('router-link',{attrs:{"to":"/create-custom-fields"}},[_c('base-button',{staticClass:"view-pallet-btn",attrs:{"type":"primary"}},[_vm._v(" Create Sections and Custom Fields ")])],1)],1),_c('div',{staticClass:"text-center mb-5"},[_c('router-link',{attrs:{"to":{
                    name: 'viewallsection',
                    params: { preselected: _vm.sectionList.lane }
                  }}},[_c('base-button',{staticClass:"view-pallet-btn",attrs:{"type":"secondary"}},[_vm._v(" View All Section ")])],1)],1)]),(_vm.user.mode === 'carrier')?_c('div',[_c('div',{staticClass:"text-center text-muted-bold mb-3"},[_vm._v(" Add Customer and Create Customer Groups ")]),_c('div',{staticClass:"text-center mb-3"},[_c('router-link',{attrs:{"to":{
                    name: 'create-custom-fields',
                    params: {
                      preselected: _vm.sectionList.customerOrCustomerGroup
                    }
                  }}},[_c('base-button',{staticClass:"gray-btn",attrs:{"type":"primary"}},[_vm._v(" Create Customers and Customer Groups ")])],1)],1),_c('div',{staticClass:"text-center mb-5"},[_c('router-link',{attrs:{"to":{
                    name: 'viewallsection',
                    params: {
                      preselected: _vm.sectionList.customerOrCustomerGroup
                    }
                  }}},[_c('base-button',{staticClass:"gray-btn",attrs:{"type":"secondary"}},[_vm._v(" View All Customers and Groups ")])],1)],1)]):_vm._e(),_c('div',[_c('div',{staticClass:"text-center text-muted-bold mb-3"},[_vm._v(" Build and view your tariffs ")]),_c('div',{staticClass:"text-center mb-3"},[_c('router-link',{attrs:{"to":"/create-tariff"}},[_c('base-button',{staticClass:"view-pallet-btn",attrs:{"type":"primary"}},[_vm._v(" Build a Tariff ")])],1)],1),_c('div',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":"/view-all-tariff"}},[_c('base-button',{staticClass:"view-pallet-btn",attrs:{"type":"secondary"}},[_vm._v(" View All Tariffs ")])],1)],1)]),(_vm.user.mode === 'broker')?_c('div',[_c('div',{staticClass:"text-center text-muted-bold mb-3"},[_vm._v(" API Key to sync to pallet. ")])]):_vm._e()])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }