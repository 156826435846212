<template>
    <div>
        <div class="row mb-2 mt-4" v-for="(range, index) in weightDiscountItems" :key="index">
            <div class="col-md-3">
                <base-input 
                    type="number" 
                    label="From" 
                    step="any" 
                    class="mb-0"
                    placeholder="3000 lbs" 
                    name="from" 
                    v-model="range.from"
                    rules="required"
                >
                </base-input>
            </div>
            <div class="col-md-3">
                <base-input 
                    type="number" 
                    label="To" 
                    step="any" 
                    class="mb-0"
                    placeholder="5000 lbs" 
                    name="to" 
                    v-model="range.to"
                    rules="required"
                >
                </base-input>
            </div>
            <div class="col-md-3">
                <base-input 
                    type="number" 
                    label="Discount" 
                    step="any" 
                    class="mb-0"
                    placeholder="%" 
                    name="discount" 
                    v-model="range.discount"
                    rules="required"
                >
                </base-input>
            </div>
            <div class="col-md-2 pr-0 ">
                <base-input 
                    type="number" 
                    label="Minimum" 
                    step="any" 
                    class="mb-0"
                    name="minimum" 
                    v-model="range.minimum"
                    rules="required"
                >
                </base-input>
            </div>

            <div class="col-md-1 mt-4 mt-md-0 text-md-right">
                <i
                  v-if="weightDiscountItems.length > 1"
                  class="fa fa-trash unit-price-delete"
                  @click="handleRemoveWeight(index)"
                >
                </i>
              </div>
        </div>

        <div class="row mb-2 mt-4">
            <div class="col-lg-6">
                <button 
                    type="button" 
                    class="btn mt-3 btn-default"
                    @click="handleAddNewWeight"
                >
                    <span>
                        <img src="../../assets/img/plus-active.svg" class="ml--2 mr-1" />
                    </span>
                    Add additional weight range discount
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    bodyClass: "landing",
    props: {
        weightDiscountItems: {
            type: Array
        }
    },
    methods: {
        handleAddNewWeight() {
            this.$emit('add-weight-discount')
        },
        handleRemoveWeight(index) {
            this.$emit('remove-weight-discount', index)
        }
    }
}
</script>