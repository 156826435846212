<template>
  <div class="tariff-app-container-height">
    <app-header
      :activeMenuName="activeMenuName"
    />
    <loader :isLoader="showLoader"></loader>
    <div class="custom-padding section-b">
      <div>
        <!-- <div>
          <router-link to="/">
            <span class="back-text-btn">Back</span>
          </router-link>
        </div> -->
        <div class="section-title-box align-items-center d-flex">
          All Tariffs
        </div>
        <div class="row align-items-center view-all-section">
          <div class="col-md-3 input-v1">
            <img class="t-search-icon" src="../../assets/img/search.png">
            <vue-input
              alternative
              placeholder="Search Tariffs"
              type="text"
              class="search-box"
              v-model="searchTariff"
              @input="(event) => searchTariffByName(event)"
            ></vue-input>
          </div>
          <div class="col-md-3 mb-15">
            <div class="">
              <div class="select-section-title">Origin</div>
              <choices-single
                :options="originList"
                v-model="selectedOrigin"
                @input="filterTariff"
              >
                <option value="0">Select Origin</option>
              </choices-single>
            </div>
          </div>
          <div class="col-md-3 mb-15">
            <div class="">
              <div class="select-section-title">Destination</div>
              <choices-single
                :options="destinationList"
                v-model="selectedDestination"
                @input="filterTariff"
              >
                <option value="0">Select Destination</option>
              </choices-single>
            </div>
          </div>
          <div class="col-md-3 mb-15">
            <div class="select-section-title">Created On</div>
            <date-picker 
              placeholder="Select date" 
              class="tariff-date-picker" 
              format="MMM DD, YYYY"
              v-model="selectedDates" 
              @input="filterTariff"
              :disabled-date="(date) => date >= new Date()" 
              range
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="view-section-tables">
        <v-app id="inspire">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="tariffList"
            :single-select="singleSelect"
            :hide-default-footer="true"
            item-key="name"
            select-enable
            class="elevation-1"
            :items-per-page="tariffList.length"
            :mobile-breakpoint="0"
          >
            <template v-slot:[`header.select`]="{ }">
              <div class="custom-control custom-checkbox">
                <input
                  id="selectAll"
                  type="checkbox"
                  class="custom-control-input"
                />
                <label for="selectAll" class="custom-control-label"></label>
              </div>
            </template>

            <template v-slot:item.select="{ item }">
              <div class="checkbox-pin">
                <div class="custom-control custom-checkbox">
                  <input
                    :id="item.id"
                    type="checkbox"
                    v-model="item.select"
                    class="custom-control-input single-checkbox"
                  />
                  <label :for="item.id" class="custom-control-label"></label>
                </div>
              </div>
            </template>
            <template v-slot:item.action="{ item }">
              <div class="d-flex">
                <base-button
                  type="primary"
                  class="btn-theme-EFEBEB table-action-button left-border btn-br1"
                  @click="showCustomerAndGroup(item.sectionDetail.customerOrCustomerGroup)"
                >
                  <img src="../../assets/img/group.png"/>
                </base-button>
                <base-button
                  type="primary"
                  class="btn-theme-EFEBEB table-action-button btn-br1"
                  @click="editDuplicateTariff(item.id,'duplicateTariff')"
                >
                  <img src="../../assets/img/duplicate.png"/>
                </base-button>
                <base-button
                  type="primary"
                  class="btn-theme-EFEBEB table-action-button btn-br1"
                  @click="editDuplicateTariff(item.id, 'editTariff')"
                >
                  <img src="../../assets/img/edit.png"/>
                </base-button>
                <base-button
                  type="primary"
                  class="btn-theme-EFEBEB table-action-button right-border"
                  @click="showDeleteConfirmationModal(item.id)"
                >
                  <img src="../../assets/img/trash.png"/>
                </base-button>
              </div>
            </template>
          </v-data-table>
        </v-app>
      </div>
    </div>

    <confirmation-modal 
      ref="confirmationModal"
      @delete="deleteTariff" 
    />

    <modal :show.sync="isModal" modal-classes="modal-dialog-centered modal-xl">
      <slot name="close-button">
        <h5>
          Customer and Customer Group
        </h5>
        <button type="button" class="modal-close" @click="isModal = false" data-dismiss="modal" aria-label="Close">
          <span :aria-hidden="true">×</span>
        </button>
      </slot>

      <div class="mt-4">
        <expanded-customer-group-table
          :tableData="customerOrCustomerGroupList"
          :isTariff="false"
          :isParentView="true"
          :isPagination="false"
          :isAction="false"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import ChoicesSingle from "@/components/SingleSelect";
import AppHeader from "@/layout/AppHeader.vue";
import Loader from "@/views/common/Loader.vue";
import ConfirmationModal from "@/views/common/ConfirmationModal.vue";
import Modal from "@/components/Modal.vue";
import ExpandedCustomerGroupTable from "@/views/customers/ExpandedCustomerGroupTable.vue";
import { API, masterAPI } from "@/api/API";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "light-table",
  components: {
    AppHeader,
    ChoicesSingle,
    Loader,
    DatePicker,
    ConfirmationModal,
    Modal,
    ExpandedCustomerGroupTable
  },
  data() {
    return {
      activeMenuName: "view-all-tariff",
      // Data properties
      searchTariff: "",
      originList: [],
      selectedOrigin: null,
      destinationList: [],
      selectedDestination: null,
      singleSelect: false,
      selected: [],
      headers: [
        { text: "Select", value: "select",sortable: false },
        { text: "Name", value: "name", sortable: false },
        { text: "Created On", value: "createdAt", sortable: false },
        { text: "Action", value: "action", class: "custom-th-class", sortable: false},
      ],
      tariffList: [],
      tariffOldList: [],
      date: moment().format("MMM DD, YYYY"),
      selectedDates: [],
      showLoader: false,
      selectedTariffId: null,
      isModal: false,
      customerOrCustomerGroupList:[],
    };
  },
  methods: {
    /**
     * Fetches all tariffs from the API and initializes data properties.
     */
    async getAllTariffs() {
      this.tariffList = [];
      this.tariffOldList = [];
      this.originList = [];
      this.destinationList = [];
      // Make API call to fetch tariff data
      let response = await masterAPI(
        API.API_ENDPOINT.tariff,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      if (response.status == 200) {
        let tariffData = response.data;
        let lanes = [];
        for (let i = 0; i < tariffData.length; i++) {
          lanes = lanes.concat(tariffData[i].sectionDetail.lane);
          let tariffObj = {
            name: tariffData[i].name,
            createdAt: moment(tariffData[i].createdAt).format("MMM Do, YYYY"),
            id: tariffData[i].id,
            action: "",
            lanes: lanes,
            sectionDetail: tariffData[i].sectionDetail,
          };

          this.tariffList.push(tariffObj);
          this.tariffOldList.push(tariffObj);
        }
        // Extract origin and destination values for filtering options
        let origin = [];
        let destination = [];
        lanes.map((item,i) => {
          let laneObj = {
            id: i,
            text: item.origin,
          };
          origin.push(laneObj);

          let destinationObj = {
            id: i,
            text: item.destination,
          };
          destination.push(destinationObj);
        });
        
        // Remove duplicate entries and update origin and destination lists
        this.originList = this.getUniqueArray(origin);
        this.destinationList = this.getUniqueArray(destination);
      }
    },
    /**
     * Returns an array of unique objects based on a specific property value.
     * @param {Array} list - The original array.
     * @returns {Array} - The array with unique objects.
     */
    getUniqueArray(list) {
      // Create a new Set with a custom equality check function
      let uniqueSet = new Set(list.map(obj => obj.text));
      
      // Convert the Set back to an array of unique objects
      let uniqueArray = Array.from(uniqueSet).map(name => {
        return list.find(obj => obj.text === name);
      });

      return uniqueArray;
    },
    /**
     * Filters the tariff list based on the search input.
     * @param {string} searchValue - The search value entered by the user.
     */
    searchTariffByName(searchValue) {
      let searchItem = this.tariffOldList
        .map((filterItem) => {
          if (
            filterItem.name.toLowerCase().indexOf(searchValue.toLowerCase()) >
            -1
          ) {
            return filterItem;
          }
        })
        .filter(function(element) {
          return element !== undefined;
        });
      this.tariffList = searchItem;
    },
    /**
     * Navigates to the edit or duplicate screen for a specific tariff.
     * @param {number} tariffId - The ID of the tariff.
     * @param {string} screenName - The name of the screen to navigate to.
     */
    editDuplicateTariff(tariffId, screenName) {
      this.$router.push({
        name: screenName,
        params: { id: tariffId },
      });
    },
    /**
     * Filters the tariff list based on selected origin,destination and date values.
     */
    filterTariff() {
      const { selectedOrigin, selectedDestination } = this;
      let filteredArray = this.tariffOldList.filter((tariffObj) => {
        const { lanes } = tariffObj;
        return lanes.some(({ origin, destination }) => {
          if (selectedOrigin && selectedDestination) {
            return origin === selectedOrigin && destination === selectedDestination;
          } else if (selectedOrigin) {
            return origin === selectedOrigin;
          } else if (selectedDestination) {
            return destination === selectedDestination;
          }
          return true;
        });
      });
      
      if (this.selectedDates.length > 0) {
        if (this.selectedDates[0]) {
          const startDate = moment(this.selectedDates[0]);
          const endDate = moment(this.selectedDates[1]);
          filteredArray = filteredArray.filter((tariffObj) => {
            const tariffDate = moment(tariffObj.createdAt, "MMM Do, YYYY").toDate();
            return moment(tariffDate).isBetween(startDate, endDate, 'day', '[]');
          });
        }
      } 
      
      this.tariffList = filteredArray;
    },
    /**
     * Shows the delete confirmation modal for a selected record.
     * @param {string} selectedRecordId - The ID of the record to be deleted.
    */
    showDeleteConfirmationModal(selectedRecordId) {
      this.selectedTariffId = selectedRecordId;
      this.$refs.confirmationModal.showDeleteConfirmation();
    },
    /**
    * Asynchronously deletes a tariff by making a request to the master API.
    */
    async deleteTariff() {
      if (this.selectedTariffId) {
        this.showLoader = true;
        let response = await masterAPI(
          API.API_ENDPOINT.tariff,
          API.API_METHOD.delete,
          this.selectedTariffId
        );

        this.showLoader = false;
        if (response.status == 200) {
          this.$toast.success("Tariff deleted successfully");
          this.getAllTariffs();
        } 
      }
    },
    /**
     * Displays a modal with a list of customers or customer groups associated with that tariff
     */
    showCustomerAndGroup(customerOrCustomerGroup) {
      this.isModal = true;
      this.customerOrCustomerGroupList = customerOrCustomerGroup;
    }
  },
  created() {
    this.getAllTariffs();
  },
};
</script>

<style>
.tariff-date-picker input {
  height: 42px;
}

.mx-calendar-panel-date:last-child {
  display: none;
}
</style>
