<template>
  <div class="wrapper tariff-app-container-height">
    <app-header
      :activeMenuName="activeMenuName"
    />
    <div class="col-md-7 container-right-border">
      <div class="custom-padding">
        <div class="mb-3">
          <span class="back-text-btn" @click="backView">
            Back
          </span>
        </div>
        <lane-form
          :isCreateLane="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/layout/AppHeader.vue";
import LaneForm from "@/views/lane/LaneForm.vue";
import { sectionList } from "@/helpers/utility";
export default {
  bodyClass: "landing",
  components: {
    AppHeader,
    LaneForm
  },
  data: function() {
    return {
      activeMenuName: "create-custom-fields",
    };
  },
  methods: {
    /**
     * Navigates to the previous page in the browser history.
    */
    backView() {
      this.$router.push({ 
        name: "viewallsection",
        params: { preselected: sectionList.lane }
      });
    },
  },
};
</script>

<style></style>
