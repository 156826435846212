<template>
  <div>
    <div v-if="item.pricingMethodTypeName == 'Fixed'">
      {{ item.value != "" ? "$" + item.value : "" }}
    </div>
    <div v-else>
      <div v-if="item.value" class="font-bold mb-1 mt-1">
        <el-dropdown trigger="click">
          <a class="btn-link view-all-link-btn mb-2">
            View all
          </a>
          <el-dropdown-menu slot="dropdown">
            <div class="row pricing-value-header mb-2">
              <div 
                v-for="(allowedValue, index) in item.pricingMethodItem.allowedValues" 
                :key="index" 
                class="col-5 font-bold text-transform-capitalize"
              >
                {{ allowedValue.key }}
              </div>
            </div>

            <div v-for="(pricingValue, i) in item.value" :key="i" class="row">
              <div 
                v-for="(allowedValue, index) in item.pricingMethodItem.allowedValues" 
                :key="index" 
                class="col-5 cmb-3"
              >
                <span v-if="allowedValue.key == 'price'">
                  {{ pricingValue[allowedValue.key] !== "" ? "$" + pricingValue[allowedValue.key] : "" }}
                </span>
                <span v-else>
                  {{ pricingValue[allowedValue.key] }}
                </span>
              </div>
            </div>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { Dropdown, DropdownItem, DropdownMenu } from "element-ui";

export default {
  name: "",
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    item: {
      type: Object,
    },
  },
};
</script>
<style></style>
