<template>
    <div>
      <loader :isLoader="showLoader"></loader>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="tableData"
        :single-select="singleSelect"
        :hide-default-footer="true"
        select-enable
        class="elevation-1"
        :items-per-page="tableData.length"
        :mobile-breakpoint="0"
      >
  
        <template v-slot:item.action="{ item }">
          <div 
            v-if="isTariff" 
            @click="removeItem(item)" 
            class="text-center"
          >
            <i v-if="isParentView" class="fa fa-times" aria-hidden="true"></i>
          </div>
          <div v-else class="d-flex">
            <base-button
              type="primary"
              class="btn-theme-EFEBEB left-border btn-br1"
              @click="editBaseRateView(item.id)"
            >
              <img src="../../assets/img/edit.png"/>
            </base-button>
            <base-button
              type="primary"
              class="btn-theme-EFEBEB table-action-button right-border"
              @click="showDeleteConfirmationModal(item.id)"
            >
              <img src="../../assets/img/trash.png"/>
            </base-button>
          </div>
        </template>
      </v-data-table>
      
      <confirmation-modal 
        ref="confirmationModal"
        @delete="deleteClassDiscount" 
      />
  
    </div>
  </template>
  
  <script>
  import Loader from "@/views/common/Loader.vue";
  import ConfirmationModal from "@/views/common/ConfirmationModal.vue";
  import { API, masterAPI } from "@/api/API";
    
  export default {
    bodyClass: "landing",
    components: {
      Loader,
      ConfirmationModal,
    },
    data: function() {
      return {
        // Component's data properties
        singleSelect: false,
        selected: [],
        headers: [
          { text: "Name", value: "name", sortable: false },
          { text: "Discount", value: "discount", sortable: false },
          { text: "Action", value: "action", sortable: false }
        ],
        selectedItem: {},
        showLoader: false,
        selectedBaseRateId: null,
      };
    },
    props: {
      // Component props
      tableData: {
        type: Array
      },
      isTariff: {
        type: Boolean
      },
      isParentView: {
        type: Boolean
      },
      sectionName: {
        type: String
      }
    },
    methods: {
      /**
       * Remove an item from the table.
       * @param {Object} item - The item to be removed.
       */
      removeItem(item) {
        // Emit an event to notify parent component to remove the item
        this.$emit("remove-item", item);
      },
      editBaseRateView(baseRateId) {
        this.$router.push({
          name: "editBaserate",
          params: {
            view: "edit",
            id: baseRateId
          }
        });
      },
      /**
       * Shows the delete confirmation modal for a selected record.
       * @param {string} selectedRecordId - The ID of the record to be deleted.
      */
      showDeleteConfirmationModal(selectedRecordId) {
        this.selectedBaseRateId = selectedRecordId;
        this.$refs.confirmationModal.showDeleteConfirmation();
      },
      /**
       * Delete the selected class discount if a base rate ID is provided.
      */
      async deleteClassDiscount() {
        if (this.selectedBaseRateId) {
          this.showLoader = true;

          let response = await masterAPI(
            API.API_ENDPOINT.classDiscount,
            API.API_METHOD.delete,
            this.selectedBaseRateId
          );

          this.showLoader = false;

          if (response.status == 200) {
              this.$toast.success(this.sectionName + " deleted successfully");
              this.$emit("remove-item", this.sectionName);
          } 
        }
      },
    },
    created() {}
  };
  </script>
  
  <style></style>
  