<template>
  <div class="wrapper">
    <div class="page-header home-page-card">
      <div class="container">
        <div class="col-lg-4 col-md-8 mx-auto">
          <div class="card">
            <!-- <div class="card-header">
              <router-link slot="brand" class="mr-lg-5" to="/">
                <img
                  class="pallet-logo w-100"
                  src="img/brand/pallet-logo.svg"
                  alt="logo"
                />
              </router-link>
            </div> -->
            <div class="card-body py-lg-5">
              <div>
                <div class="text-center text-muted-bold mb-3">
                  Build and view the sections used to create your Tariff
                </div>
                <div class="text-center mb-3">
                  <router-link to="/create-custom-fields">
                    <base-button type="primary" class="view-pallet-btn">
                      Create Sections and Custom Fields
                    </base-button>
                  </router-link>
                </div>
                <div class="text-center mb-5">
                  <router-link
                    :to="{
                      name: 'viewallsection',
                      params: { preselected: sectionList.lane }
                    }"
                  >
                    <base-button type="secondary" class="view-pallet-btn">
                      View All Section
                    </base-button>
                  </router-link>
                </div>
              </div>
              <div v-if="user.mode === 'carrier'">
                <div class="text-center text-muted-bold mb-3">
                  Add Customer and Create Customer Groups
                </div>
                <div class="text-center mb-3">
                  <router-link
                    :to="{
                      name: 'create-custom-fields',
                      params: {
                        preselected: sectionList.customerOrCustomerGroup
                      }
                    }"
                  >
                    <base-button type="primary" class="gray-btn">
                      Create Customers and Customer Groups
                    </base-button>
                  </router-link>
                </div>
                <div class="text-center mb-5">
                  <router-link
                    :to="{
                      name: 'viewallsection',
                      params: {
                        preselected: sectionList.customerOrCustomerGroup
                      }
                    }"
                  >
                    <base-button type="secondary" class="gray-btn">
                      View All Customers and Groups
                    </base-button>
                  </router-link>
                </div>
              </div>
              <div>
                <div class="text-center text-muted-bold mb-3">
                  Build and view your tariffs
                </div>
                <div class="text-center mb-3">
                  <router-link to="/create-tariff">
                    <base-button type="primary" class="view-pallet-btn">
                      Build a Tariff
                    </base-button>
                  </router-link>
                </div>
                <div class="text-center">
                  <router-link to="/view-all-tariff">
                    <base-button type="secondary" class="view-pallet-btn">
                      View All Tariffs
                    </base-button>
                  </router-link>
                </div>
              </div>
              <div v-if="user.mode === 'broker'">
                <div class="text-center text-muted-bold mb-3">
                  API Key to sync to pallet.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { sectionList } from "@/helpers/utility";
import { API, masterAPI } from "@/api/API";

export default {
  components: {},
  data: function() {
    return {
      accountEmail: "",
      carrierName: "",
      sectionList: sectionList,
      user: {}
    };
  },
  methods: {},
  async created() {
    let response = await masterAPI(
      API.API_ENDPOINT.user,
      API.API_METHOD.get,
      undefined,
      {}
    );
    this.user = response.data;
  }
};
</script>
<style></style>
