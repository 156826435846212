<template>
  <div>
    <customer-form
      :isCreate="true"
      @reset-detail="resetDetail"
    />
  </div>
</template>
<script>

import CustomerForm from "@/views/customers/CustomerForm.vue";

export default {
  components: {
    CustomerForm
  },
  data: function() {
    return {
      
    };
  },
  methods: {
    /**
     * Resets the detail and emits a "save-module" event.
     */
     resetDetail() {
      this.$emit("save-module");
    }
  }
};
</script>
<style></style>
