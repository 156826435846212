<template>
    <div class="d-flex justify-content-end mt-3 mt-md-1 mt-lg-0 toggle-container">
        <div class="d-flex toggle-switch">
            <button
                type="button" 
                class="btn switch-slider px-3 pt-1" 
                :class="{ 'btn-primary': tariffType === 'private' }"
                style="margin-right: -3px;"
                @click="updateMode('private')"
            >
                Private
            </button>
            <button
                type="button" 
                class="btn switch-slider px-3 pt-1" 
                :class="{ 'btn-primary': tariffType === 'public' }" 
                @click="updateMode('public')"
            >
                Public
            </button>
        </div>
    </div>
</template>

<script>
export default {
    bodyClass: "landing",
    props: {
     value: String,
    },
    computed: {
        tariffType() {
            return this.value; // Use the 'value' prop
        }
    },
    methods: {
        updateMode(mode) {
            this.$emit('input', mode); // Emit the new mode to the parent
        }
    }
}
</script>

<style scoped>
.toggle-switch {
  background-color: #F4F4F4; 
  border-radius: 32px; 
  padding: 4px; 
  justify-content: center;
  align-items: center;
}

.switch-slider {
  border-radius: 32px;  
  transition: all 500ms ease; 
  height: 30px;
}

.switch-slider:hover {
  box-shadow: none;
  transform: none;
}
</style>
