<template>
  <div>
    <loader :isLoader="showLoader"></loader>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="tableData"
      :single-select="singleSelect"
      :hide-default-footer="true"
      select-enable
      class="elevation-1"
      :items-per-page="tableData.length"
      :mobile-breakpoint="0"
    >
      <template v-slot:item.dataTypeFields="{ item }">
        <div class="font-bold mb-1 mt-1">
          <a
            class="btn-link view-all-link-btn mb-2"
            @click="viewAllDataTypeFields(item)"
          >
            View all
          </a>
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <div v-if="isTariff" @click="removeItem(item)" class="text-center">
          <i v-if="isParentView" class="fa fa-times" aria-hidden="true"></i>
        </div>
        <div v-else class="d-flex">
          <base-button
            type="primary"
            class="btn-theme-EFEBEB left-border btn-br1"
            @click="editDuplicateView(item.id, true)"
          >
            <img src="../../assets/img/edit.png"/>
          </base-button>
          <base-button
            type="primary"
            class="btn-theme-EFEBEB table-action-button btn-br1"
            @click="editDuplicateView(item.id, false)"
          >
            <img src="../../assets/img/duplicate.png"/>
          </base-button>
          <base-button
            type="primary"
            class="btn-theme-EFEBEB table-action-button right-border"
            @click="showDeleteConfirmationModal(item.id)"
          >
            <img src="../../assets/img/trash.png"/>
          </base-button>
        </div>
      </template>
    </v-data-table>

    <view-all-data-type-fields-modal
      ref="viewAllDataTypeFieldsModal"
      :selectedItem="selectedItem"
    />

    <confirmation-modal
      ref="confirmationModal"
      @delete="deleteAccessorialFsc"
    />
  </div>
</template>

<script>
import { sectionList } from "@/helpers/utility";
import { API, masterAPI } from "@/api/API";
import ViewAllDataTypeFieldsModal from "@/views/common/ViewAllDataTypeFieldsModal.vue";
import Loader from "@/views/common/Loader.vue";
import ConfirmationModal from "@/views/common/ConfirmationModal.vue";

export default {
  bodyClass: "landing",
  components: {
    ViewAllDataTypeFieldsModal,
    Loader,
    ConfirmationModal
  },
  data: function() {
    return {
      // Component's data properties
      singleSelect: false,
      selected: [],
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Data Type Fields", value: "dataTypeFields", sortable: false },
        { text: "Created On", value: "createdAt", sortable: false },
        { text: "Action", value: "action", sortable: false }
      ],
      selectedItem: {},
      showLoader: false,
      accessorialFscId: null
    };
  },
  props: {
    // Component props
    tableData: {
      type: Array
    },
    isTariff: {
      type: Boolean
    },
    isParentView: {
      type: Boolean
    },
    sectionName: {
      type: String
    }
  },
  methods: {
    /**
     * Remove an item from the table.
     * @param {Object} item - The item to be removed.
     */
    removeItem(item) {
      // Emit an event to notify parent component to remove the item
      this.$emit("remove-item", item);
    },
    /**
     * Navigates to the edit or duplicate view based on the provided parameters.
     * @param {string} laneId - The ID of the lane.
     * @param {boolean} isEdit - Indicates whether the view is for editing or duplicating.
     */
    editDuplicateView(laneId, isEdit) {
      let viewName = this.getViewName(isEdit);
      let screenName = "editFuelSurcharge";
      if (this.sectionName === sectionList.accessorial) {
        screenName = "editAccessorial";
      }
      this.$router.push({
        name: screenName,
        params: {
          view: viewName,
          id: laneId
        }
      });
    },
    /**
     * Gets the view name based on the edit mode.
     * @param {boolean} editMode - Indicates whether the view is for editing.
     * @returns {string} - The view name ('edit' or 'duplicate').
     */
    getViewName(editMode) {
      return editMode ? "edit" : "duplicate";
    },
    /**
     * Shows the delete confirmation modal for a selected record.
     * @param {string} selectedRecordId - The ID of the record to be deleted.
     */
    showDeleteConfirmationModal(selectedRecordId) {
      this.accessorialFscId = selectedRecordId;
      this.$refs.confirmationModal.showDeleteConfirmation();
    },
    /**
     * Asynchronously deletes an accessorial or FSC (Fuel Surcharge) by its ID.
     *
     */
    async deleteAccessorialFsc() {
      if (this.accessorialFscId) {
        this.showLoader = true;
        let response = await masterAPI(
          API.API_ENDPOINT.accessorial,
          API.API_METHOD.delete,
          this.accessorialFscId
        );

        this.showLoader = false;
        if (response.status == 200) {
          this.$toast.success(this.sectionName + " deleted successfully");
          this.$emit("remove-item", this.sectionName);
        }
      }
    },
    /**
     * Opens a modal to view all data type fields of a selected item.
     *
     * @param {Object} item - The selected item to view data type fields for.
     */
    viewAllDataTypeFields(item) {
      this.selectedItem = item;
      this.$refs.viewAllDataTypeFieldsModal.openModal();
    }
  },
  created() {}
};
</script>

<style></style>
