<template>
  <div class="d-flex profile-container">
    <choices-single 
      v-model="selectedCurrencyUser" 
      id="currencyListDropdown"
      :options="currencyList"
      class="currency-dropdown-header"
      @input="event => changeCurrency(event)" 
    >
      <option value="0">Select Currency</option>
    </choices-single>

    <img class="mr-3" src="../assets/img/notification.svg" alt="logo" />

    <el-dropdown trigger="click">
      <img class="c-p" src="../assets/img/user.svg" alt="logo" />
      <el-dropdown-menu slot="dropdown">
        <span class="dropdown-item logout-icon" v-on:click="logout">
          Logout
        </span>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { Dropdown, DropdownItem, DropdownMenu } from "element-ui";
import { API, masterAPI } from "@/api/API";
import ChoicesSingle from "@/components/SingleSelect";
import { mapGetters } from "vuex";
import { currencyList } from "@/helpers/utility";

export default {
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    ChoicesSingle
  },
  data: function() {
    return {
      currencyList: currencyList
    }
  },
  computed: {
    ...mapGetters(['currencyType']),
    selectedCurrencyUser: {
      get() {
        return this.currencyType;
      },
      set(value) {
        this.changeCurrency(value);
      }
    }
  },
  methods: {
    logout: async function() {
      let response = await masterAPI(
        API.API_ENDPOINT.logout,
        API.API_METHOD.post,
        undefined,
        undefined
      );

      if (response.status == 200) {
        this.$toast.success("Logout successful", { timeout: 100 });
        localStorage.removeItem("id_token");
        localStorage.removeItem("p_key");
        this.$router.push({ path: "/", name: "register" }).catch(() => {});
      }
    },
    changeCurrency(value) {
      this.$store.dispatch('updateCurrencyType', value);
    }
  },
};
</script>

<style></style>
