<template>
  <div>
    <accessorial-fsc-form
      :moduleView="sectionList.accessorial"
      :isCreate="false"
    />
  </div>
</template>

<script>
import AccessorialFscForm from "@/views/accessorial/AccessorialFscForm.vue";
import { sectionList } from "@/helpers/utility";

export default {
  bodyClass: "landing",
  components: {
    AccessorialFscForm,
  },
  data: function() {
    return {
      sectionList: sectionList,
    };
  },
};
</script>

<style></style>
