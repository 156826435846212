<template>
  <div class="">
    <accessorial-fsc-form
      :moduleView="moduleType"
      :isCreate="true"
      @reset-detail="resetDetail"
    />
  </div>
</template>

<script>
import AccessorialFscForm from "@/views/accessorial/AccessorialFscForm.vue";

export default {
  bodyClass: "landing",
  components: {
    AccessorialFscForm
  },
  data: function() {
    return {
      
    };
  },
  props: {
    // Component props
    moduleType: {
      type: String,
    },
  },
  methods: {
    /**
     * Resets the detail and emits a "save-module" event.
     */
    resetDetail() {
      this.$emit("save-module");
    }
  },
};
</script>

<style></style>
