import Vue from "vue";
import Vuex from 'vuex';
import { API, masterAPI } from "@/api/API";
import { userModes } from "@/helpers/utility";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
      user: {},
      currencyType: 'USD',
    },
    mutations: {
      setUser (state, user) {
        state = user
      },
      setCurrencyType(state, currencyType) {
        state.currencyType = currencyType;
      },
    },
    actions: {
      async getProfileData({ commit, state }) {
        let response = await masterAPI(
            API.API_ENDPOINT.user,
            API.API_METHOD.get,
            undefined,
            undefined
          );
          if(response.status == 200) {
            let user = response.data;
            commit('setUser', Object.assign(state,{user}));
          }
      },
      updateCurrencyType({ commit }, currencyType) {
        commit('setCurrencyType', currencyType);
      },
    },
    getters: {
      userIsBroker: (state) => state.user.mode == userModes.BROKER,
      user: (state) => state.user,
      currencyType: (state) => state.currencyType,
    },
});

export default store;
