<template>
  <div>
    <tariff-view
      :screenName="screenName"
    />
  </div>
</template>

<script>
import TariffView from '@/views/tariff/TariffView.vue';

export default {
  bodyClass: "landing",
  components: {
    TariffView,
  },
  data: function() {
    return {
      screenName: 'update',
    };
  },
};
</script>
