<template>
  <div class="wrapper tariff-app-container-height">
    <app-header
      v-if="!isCreate"
      :activeMenuName="activeMenuName"
    />
    <div :class="!isCreate ? 'col-md-7 container-right-border' : ''">
      <div :class="!isCreate ? 'custom-padding' : ''">
        <div v-if="!isCreate" class="mb-3">
          <span class="back-text-btn" @click="backView">
            Back
          </span>
        </div>
        <ValidationObserver v-slot="{ validate }" ref="formValidator">
          <form
            @submit.prevent="validate().then(saveCondition(false))"
            class="needs-validation"
            id="add-qo"
          >
            <div class="row mb-4">
              <div class="col-md-12 col-lg-5">
                <div class="create-section-text mb-2">
                  Select type of condition
                </div>
                <ValidationProvider
                  rules="required"
                  name="Type of condition"
                  v-slot="{ errors }"
                >
                  <choices-single
                    id="conditionType"
                    :options="typeOfConditionList"
                    :value="selectedTypeOfCondition"
                    v-model="selectedTypeOfCondition"
                    @input="event => selectTypeOfCondition(event)"
                  >
                    <option value="0">Select</option>
                  </choices-single>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div v-if="isCreate" class="create-section-text mb-2">
              Create a new field for this section
            </div>
            <div class="row">
              <div class="col-md-12 col-lg-5">
                <base-input
                  type="text"
                  label="Name of field"
                  name="Name"
                  rules="required"
                  v-model="name"
                >
                </base-input>

                <div class="form-group has-label">
                  <label> Description of the section (optional) </label>
                  <textarea
                    label="Name of Section"
                    size="sm"
                    class="form-control"
                    rows="6"
                    v-model="description"
                  >
                  </textarea>
                </div>
              </div>
              <div class="col-lg-6"></div>
            </div>

            <!-- Currency type dropdown starts-->
            <currency-selection 
              @currency-default-changed="handleCurrencyDefaultChange"
            />
            <!-- Currency type dropdown ends -->

            <div v-if="selectedConditionTypeDetail">
              <condition-type-fields
                ref="conditionTypeFieldsComponent"
                :conditionTypeFields="conditionTypeFields"
                :conditionUnitTypeList="conditionUnitTypeList"
                :conditionDirection="conditionDirection"
                :conditionAffects="conditionAffects"
                :selectedConditionTypeDetail="selectedConditionTypeDetail"
                :selectedTypeOfCondition="selectedTypeOfCondition"
                @add-thresholds="addThresholds"
                @remove-thresholds="removeThresholds"
                :currencySymbol="currencySymbol"
              />
            </div>

            <min-max-charges-input ref="minMaxChargeInput" />
            <button type="submit" class="btn mt-3 btn-default">
              Save
            </button>

            <div>
              <button 
                type="button" 
                class="btn mt-3 btn-default"
                @click="saveCondition(true)"
              >
                Save and Exit
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/layout/AppHeader.vue";
import ChoicesSingle from "@/components/SingleSelect";
import MinMaxChargesInput from "@/views/common/MinMaxChargesInput.vue";
import ConditionTypeFields from "@/views/condition/ConditionTypeFields.vue";
import CurrencySelection from "@/views/common/CurrencySelection.vue";

import {
  accountModuleApi,
  conditionAffects,
  conditionDirection,
  conditionsTypeKey,
  conditionType,
  cubeRuleUnitType,
  getAccessorialKeyByName,
  getAccessorialNameByKey,
  getOptionDetailByName,
  getTypeItem,
  heightRuleUnitType,
  linearFootRuleUnitType,
  getCurrencyType
} from "@/helpers/utility";
import { API, masterAPI } from "@/api/API";

export default {
  bodyClass: "landing",
  components: {
    AppHeader,
    ChoicesSingle,
    MinMaxChargesInput,
    ConditionTypeFields,
    CurrencySelection
  },
  data: function() {
    return {
      // Data properties
      activeMenuName: "create-custom-fields",
      name: "",
      description: "",
      conditionTypeList: [],
      conditionType: conditionType,
      dataTypeFieldList: [
        {
          type: "",
          item: [],
          pricingMethods: null
        }
      ],
      dbConditionList: [],
      isDuplicate: false,
      typeOfConditionList: [],
      selectedTypeOfCondition: "",
      conditionTypeFields: {
        thresholds: [
          {
            metric: "",
            direction: "",
            value: "",
            unitType: ""
          }
        ],
        affects: {
          affects: "",
        }
      },
      conditionUnitTypeList: [],
      conditionDirection: [],
      conditionAffects: [],
      selectedConditionTypeDetail: null,
      alreadySavedCondition: false,
      id: "",
      currencyDefault: "USD"
    };
  },
  props: {
    // Component props
    moduleView: {
      type: String
    },
    isCreate: {
      type: Boolean
    }
  },
  watch: {
    // Watchers for name and description properties
    name: {
      handler(value) {
        this.changeConditionFormHandler("name", value);
      },
      immediate: true
    },
    description: {
      handler(value) {
        this.changeConditionFormHandler("description", value);
      },
      immediate: true
    }
  },
  computed: {
    // Computed property for recordId
    recordId() {
      return +this.$route.params.id;
    },
    currentViewName() {
      return this.$route.params.view;
    },
    currencySymbol() {
      const currencyType= getCurrencyType(this.currencyDefault)
      return currencyType;
    }
  },
  methods: {
    /**
     * Event handler for changing name and description fields.
     * @param {string} field - The field name being changed.
     * @param {string} value - The new value of the field.
     */
    changeConditionFormHandler(field, value) {
      this.isDuplicate = value != this.dbConditionList[field];
    },
    /**
     * Event handler for changing currency type.
     */
     handleCurrencyDefaultChange(newValue) {
      this.currencyDefault = newValue;
    },
    /**
     * Save condition logic.
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the save or update.
     */
    saveCondition(isSaveExit) {
      this.$refs.conditionTypeFieldsComponent.isAffectsValid = true;

      this.$nextTick(() => {
        if (this.isCreate) {
          this.isDuplicate = true;
          if (this.alreadySavedCondition) {
            this.updateCondition(isSaveExit);
          } else {
            this.createCondition(isSaveExit);
          }
        } else {
          if (this.currentViewName == "duplicate") {
            this.createCondition(isSaveExit);
          } else {
            this.updateCondition(isSaveExit);
          }
        }
      });
    },
    /**
     * Asynchronously creates a condition by performing form validation and making an API call.
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the save.
     */
    async createCondition(isSaveExit) {
      // Perform form validation
      const isFormValid = await this.checkFormValidator();
      let minMaxValid = this.$refs.minMaxChargeInput.checkMinMaxValueValidation();
      if (isFormValid && minMaxValid) {
        if (this.isDuplicate) {
          let minChargeValue = this.$refs.minMaxChargeInput.minValue;
          let maxChargeValue = this.$refs.minMaxChargeInput.maxValue;
          let conditionType = await getAccessorialNameByKey(
            this.typeOfConditionList,
            this.selectedTypeOfCondition
          );
          const dataTypeFields = getTypeItem(this.dataTypeFieldList);
          // Prepare payload for API request
          let bodyPayload = JSON.stringify({
            conditionType: conditionType,
            name: this.name,
            description: this.description,
            dataTypeFields: dataTypeFields,
            conditionTypeFields: this.conditionTypeFields,
            minCharge: minChargeValue,
            maxCharge: maxChargeValue
          });

          // Make an API request to create a new condition
          let response = await masterAPI(
            API.API_ENDPOINT.condition,
            API.API_METHOD.post,
            undefined,
            bodyPayload
          );

          if (response.status == 200) {
            if (this.isCreate) {
              this.alreadySavedCondition = true;
              this.id = response.data.id;
              this.$toast.success("Condition created successfully");
            } else {
              this.$toast.success("Condition duplicate successfully");
            }

            if (isSaveExit) {
              this.backView();
            }
          } else {
            let error = response.data.message;
            this.$toast.error(error.replaceAll('"', ""));
          }
        } else {
          this.$toast.error(
            "Condition cannot be duplicated until a change as been made"
          );
        }
      }
    },
    /**
     * Asynchronously updates a condition by performing form validation and making an API call.
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the update.
     */
    async updateCondition(isSaveExit) {
      const isFormValid = await this.checkFormValidator();
      let minMaxValid = this.$refs.minMaxChargeInput.checkMinMaxValueValidation();
      if (isFormValid && minMaxValid) {
        let minChargeValue = this.$refs.minMaxChargeInput.minValue;
        let maxChargeValue = this.$refs.minMaxChargeInput.maxValue;
        let conditionType = await getAccessorialNameByKey(
          this.typeOfConditionList,
          this.selectedTypeOfCondition
        );
        const dataTypeFields = getTypeItem(this.dataTypeFieldList);

        let bodyPayload = JSON.stringify({
          conditionType: conditionType,
          name: this.name,
          description: this.description,
          dataTypeFields: dataTypeFields,
          conditionTypeFields: this.conditionTypeFields,
          minCharge: minChargeValue,
          maxCharge: maxChargeValue
        });
        
        let conditionId = this.recordId ? this.recordId : this.id;

        let response = await masterAPI(
          API.API_ENDPOINT.condition,
          API.API_METHOD.put,
          conditionId,
          bodyPayload
        );

        if (response.status == 200) {
          this.$toast.success("Condition update successfully");

          if (isSaveExit) {
            this.backView();
          }
        } else {
          let error = response.data.message;
          this.$toast.error(error.replaceAll('"', ""));
        }
      }
    },
    /**
     * Removes a field from the data type field list.
     * If there is only one item in the list, displays an error toast.
     * @param {number} mainIndex - The main index of the dataTypeFieldList.
     * @param {number} subIndex - The sub-index of the item within the dataTypeFieldList.
     */
    removeField(mainIndex, subIndex) {
      let itemLen = this.dataTypeFieldList[mainIndex].item.length;
      if (itemLen > 1) {
        this.dataTypeFieldList[mainIndex].item.splice(subIndex, 1);
      } else {
        this.$toast.error("Atleast one item required");
      }
      this.isDuplicate = true;
    },
    /**
     * Adds a field to the data type field list at the specified main index.
     * Performs form validation before adding the field.
     * @param {number} mainIndex - The main index of the dataTypeFieldList.
     */
    async addField(mainIndex) {
      const isFormValid = await this.checkFormValidator();
      if (isFormValid) {
        let unitPriceObj = {};
        let allowedValues = this.dataTypeFieldList[mainIndex].pricingMethods
          .allowedValues;
        let fieldItemObj = allowedValues.reduce((obj, allowedValue) => {
          obj[allowedValue.key] = "";
          return obj;
        }, unitPriceObj);

        this.dataTypeFieldList[mainIndex].item.push(fieldItemObj);
        this.isDuplicate = true;
      }
    },
    /**
     * Navigates back to the previous view using the Vue Router.
     */
    backView() {
      this.$router.push({ 
        name: "viewallsection",
        params: { preselected: this.moduleView }
      });
    },

    /**
     * Performs form validation using the formValidator component.
     * @returns {Promise<boolean>} - A promise that resolves to true if the form is valid, false otherwise.
     */
    async checkFormValidator() {
      return await this.$refs.formValidator.validate();
    },
    /**
     * Updates the selected type of condition and sets a flag for duplication.
     * @param {string} e - The selected type of condition.
     */
    async selectTypeOfCondition(e) {
      this.conditionUnitTypeList = [];
      this.selectedTypeOfCondition = e;
      this.isDuplicate = true;
      this.selectedConditionTypeDetail = getOptionDetailByName(
        this.typeOfConditionList,
        e
      );

      this.setConditionUnitTypeList();
    },
    /**
     * Asynchronously retrieves condition data from the server based on the recordId.
     */
    async getCondition() {
      if (this.recordId) {
        let response = await masterAPI(
          API.API_ENDPOINT.condition,
          API.API_METHOD.get,
          this.recordId,
          undefined
        );

        if (response.status == 200) {
          this.setConditionDetail(response.data);
          this.dbConditionList = response.data;
        }
      }
    },
    /**
     * Sets the component state with condition details received from the server.
     * @param {Object} conditionData - The condition data retrieved from the server.
     */
    setConditionDetail(conditionData) {
      this.name = conditionData.name;
      this.description = conditionData.description;
      this.selectedTypeOfCondition = getAccessorialKeyByName(
        this.typeOfConditionList,
        conditionData.conditionType
      );

      setTimeout(() => {
        document.querySelector(
          "#conditionType .choices__item"
        ).innerText = this.selectedTypeOfCondition;
      }, 30);

      let priceMethods = conditionData.dataTypeFields;
      this.dataTypeFieldList = priceMethods.map((fieldItem, index) => {
        const priceMethodDetail = getOptionDetailByName(
          this.conditionTypeList,
          fieldItem.type
        );

        setTimeout(() => {
          document.querySelector(
            `#data_type_${index} .choices__item`
          ).innerText = fieldItem.type;
        }, 30);

        return {
          type: fieldItem.type,
          item: fieldItem.item,
          pricingMethods: priceMethodDetail
        };
      });

      this.conditionTypeFields = conditionData.conditionTypeFields;
      this.selectedConditionTypeDetail = getOptionDetailByName(
        this.typeOfConditionList,
        this.selectedTypeOfCondition
      );
      this.setConditionUnitTypeList();
      if (conditionData.minCharge) {
        this.$refs.minMaxChargeInput.minValue = conditionData.minCharge;
        this.$refs.minMaxChargeInput.showMinInput = true;
      }

      if (conditionData.maxCharge) {
        this.$refs.minMaxChargeInput.maxValue = conditionData.maxCharge;
        this.$refs.minMaxChargeInput.showMaxInput = true;
      }
    },
    /**
     * Sets the condition unit type list based on the selected condition type.
     */
    setConditionUnitTypeList() {
      if (
        this.selectedConditionTypeDetail.key ===
        conditionsTypeKey.linearFootRule
      ) {
        this.conditionUnitTypeList = linearFootRuleUnitType;
      }

      if (this.selectedConditionTypeDetail.key === conditionsTypeKey.cubeRule) {
        this.conditionUnitTypeList = cubeRuleUnitType;
      }

      if (
        this.selectedConditionTypeDetail.key === conditionsTypeKey.heightRule
      ) {
        this.conditionUnitTypeList = heightRuleUnitType;
      }
      this.conditionDirection = conditionDirection;
      this.conditionAffects = conditionAffects;
    },
    /**
     * Asynchronously retrieves available conditions and pricing methods.
     * Populates instance properties with the fetched data.
     * @returns {Promise<void>} - A promise that resolves when data retrieval and processing are complete.
     */
    async getAvailableConditionsAndPricingMethods() {
      this.typeOfConditionList = await accountModuleApi(
        API.API_ENDPOINT.availableConditions
      );
      this.conditionTypeList = await accountModuleApi(
        API.API_ENDPOINT.conditionsPricingMethods
      );
      this.getCondition();
    },
    /**
     * Asynchronously adds thresholds after validating the form.
     * Sets the 'isAffectsValid' flag to false initially.
     * Validates the form and if valid, adds a new threshold.
    */
    async addThresholds() {
      const isFormValid = await this.checkFormValidator();
      if (isFormValid) {
        let thresholdsObj = {
          metric: "",
          direction: "",
          value: "",
          unitType: ""
        }

        this.conditionTypeFields.thresholds.push(thresholdsObj);
      }
    },
    /**
     * Removes a threshold at the specified index from the thresholds array.
     * @param {number} thresholdsIndex - The index of the threshold to be removed.
     */
    removeThresholds(thresholdsIndex) {
      this.conditionTypeFields.thresholds.splice(thresholdsIndex, 1);
    },
  },
  created() {
    this.getAvailableConditionsAndPricingMethods();
  }
};
</script>

<style></style>
