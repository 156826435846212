<template>
  <div>
    <app-header
        v-if="!isCreate"
        :activeMenuName="activeMenuName"
      />
    <div
      :class="
        !isCreate
          ? 'wrapper row tariff-app-container-height'
          : 'wrapper tariff-app-container-height'
      "
    >      
      <div :class="!isCreate ? 'col-md-7 container-right-border' : ''">
        <div :class="!isCreate ? 'custom-padding' : ''">
          <div v-if="!isCreate" class="mb-3">
            <span class="back-text-btn" @click="backView">
              Back
            </span>
          </div>
          <ValidationObserver v-slot="{ validate }" ref="formValidator">
            <form
              @submit.prevent="
                validate().then(saveCustomerOrCustomerGroup(false))
              "
              class="needs-validation"
              id="add-qo"
            >
              <div>
                <!-- <div class="tarriff-title-text mb-4">
                  Create a Customer or Customer Group
                </div>
                <div class="tarriff-sub-title-text mb-4">
                  Create a Customer or Customer Group and and link them to any tariff sheet
                </div> -->
                <div v-if="isCreate">
                  <div class="create-section-text mb-2">
                    Select Customer or Customer Group
                  </div>

                  <div class="field-dropdown-main mb-4">
                    <choices-single
                      id="customerDropdown"
                      :options="dropdownOptionList"
                      v-model="selectedOption"
                      @input="event => selectOption(event)"
                    >
                      <option value="0">Select</option>
                    </choices-single>
                  </div>
                </div>

                <div v-if="selectedOption || !isCreate">
                  <div class="row">
                    <div class="col-md-12 col-lg-5">
                      <base-input
                        type="text"
                        :label="selectedOption + ' Name'"
                        :name="selectedOption + ' Name'"
                        rules="required"
                        v-model="name"
                      >
                      </base-input>
                    </div>
                    <div class="col-lg-6"></div>
                  </div>
                  <div v-if="isCustomerApi()" class="row">
                    <div class="col-md-12 col-lg-5">
                      <base-input
                        type="text"
                        :label="
                          `${selectedOption} Email ${invited ? '(Invited)' : ''}`
                        "
                        :name="`${selectedOption} Email`"
                        rules="required"
                        v-model="email"
                      >
                      </base-input>
                    </div>
                    <div class="col-lg-6"></div>
                  </div>

                  <div v-if="isCustomerApi()">
                    <div class="create-section-text mb-2">
                      Add this customer to an existing customer group
                    </div>
                    <div class="customer-group-dropdown">
                      <multiselect
                        placeholder="Select a customer group"
                        :multiple="true"
                        v-model="selectedCustomerGroupList"
                        :options="customerGroupList"
                        :close-on-select="true"
                        :clear-on-select="true"
                        label="name"
                        track-by="name"
                        @input="event => selectCustomerGroup(event)"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div v-else>
                    <div class="create-section-text mb-2">
                      Add customers to this customer group
                    </div>
                    <div class="customer-group-dropdown">
                      <multiselect
                        placeholder="Select a customer"
                        :multiple="true"
                        v-model="selectedCustomerList"
                        :options="customerList"
                        :close-on-select="true"
                        :clear-on-select="true"
                        label="name"
                        track-by="name"
                        @input="event => selectedCustomer(event)"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="form-group has-label"></div>
                  <div v-if="isCustomerApi() && apiKey.length > 0" class="row">
                    <div class="col-12 create-section-text mb-2">
                      API Key to sync your customer with Pallet
                    </div>
                    <div class="col-12 mb-3">
                      <div>{{ apiKey }}</div>
                    </div>
                  </div>
                  <button type="submit" class="btn mt-3 btn-default">
                    Save
                  </button>
                  <div>
                    <button
                      type="button"
                      class="btn mt-3 btn-default"
                      @click="saveCustomerOrCustomerGroup(true)"
                    >
                      Save and Exit
                    </button>
                  </div>
                  <div v-if="isCustomerApi()">
                    <button
                      type="button"
                      class="btn btn-theme-FCBB5C mt-3"
                      @click="inviteCustomerToPallet"
                    >
                      {{ invited === true ? "Re-invite" : "Invite" }}
                      Customer to Pallet
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/layout/AppHeader.vue";
import ChoicesSingle from "@/components/SingleSelect";
import {
  filterCustomerObjectsById,
  getAllCustomerOrCustomerGroupApi,
  sectionList
} from "@/helpers/utility";
import { API, masterAPI } from "@/api/API";
import Multiselect from "vue-multiselect";

export default {
  bodyClass: "landing",
  components: {
    AppHeader,
    ChoicesSingle,
    Multiselect
  },
  data: function() {
    return {
      activeMenuName: "create-custom-fields",
      id: "",
      name: "",
      email: "",
      apiKey: "",
      invited: false,
      selectedOption: null,
      dropdownOptionList: [
        {
          id: 0,
          text: "Customer"
        },
        {
          id: 1,
          text: "Customer Group"
        }
      ],
      customerList: [],
      selectedCustomerList: [],
      customerGroupList: [],
      selectedCustomerGroupList: [],
      alreadySavedCustomerOrCustomerGroup: false,
    };
  },
  props: {
    // Component props
    isCreate: {
      type: Boolean
    }
  },
  computed: {
    // Computed property for recordId
    recordId() {
      return +this.$route.params.id;
    },
    isCustomerGroupView() {
      return (
        this.$route.params.view &&
        this.$route.params.view.includes("edit-customer-group")
      );
    }
  },
  methods: {
    selectOption(e) {
      this.selectedOption = e;
      this.alreadySavedCustomerOrCustomerGroup = false;
      this.name = "";
    },
    /**
     * Saves a customer or customer group by calling the appropriate method based on 'isCreate' flag.
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the save or update.
     */
    saveCustomerOrCustomerGroup(isSaveExit) {
      if (this.isCreate) {
        if (this.alreadySavedCustomerOrCustomerGroup) {
          this.updateCustomerOrCustomerGroup(isSaveExit);  
        } else {
          this.createCustomerOrCustomerGroup(isSaveExit);
        }
      } else {
        this.updateCustomerOrCustomerGroup(isSaveExit);
      }
    },

    async inviteCustomerToPallet() {
      const isFormValid = await this.$refs.formValidator.validate();

      if (isFormValid && this.isCustomerApi()) {
        let apiEndPoint =
          API.API_ENDPOINT.customer +
          "/" +
          this.id +
          "/" +
          API.API_ENDPOINT.inviteCustomer;

        let response = await masterAPI(
          apiEndPoint,
          API.API_METHOD.post,
          undefined,
          JSON.stringify({})
        );

        if (response.status === 200) {
          this.$toast.success("Customer invited successfully");
        } else {
          let error = response.data.message;
          this.$toast.error(error.replaceAll('"', ""));
        }
      }
    },
    /**
     * Creates a new customer or customer group and handles the API response.
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the save.
     */
    async createCustomerOrCustomerGroup(isSaveExit) {
      const isFormValid = await this.$refs.formValidator.validate();

      if (isFormValid) {
        let bodyPayload, apiEndPoint;

        if (this.isCustomerApi()) {
          apiEndPoint = API.API_ENDPOINT.customer;
          bodyPayload = {
            name: this.name,
            email: this.email
          };
        } else {
          let idList = this.selectedCustomerList.map(fieldItem => fieldItem.id);
          apiEndPoint = API.API_ENDPOINT.customerGroup;
          bodyPayload = {
            name: this.name,
            customers: idList
          };
        }

        let response = await masterAPI(
          apiEndPoint,
          API.API_METHOD.post,
          undefined,
          JSON.stringify(bodyPayload)
        );

        if (response.status == 200) {
          if (this.isCustomerApi()) {
            let customerResponse = response.data;
            let customerId = customerResponse.id;
            this.apiKey = customerResponse.apiKey;
            this.id = customerId;
            await this.assignCustomerGroup(customerId);
            this.$toast.success("Customer created successfully");
          } else {
            this.id = response.data.id;
            this.$toast.success("Customer group created successfully");
          }

          if (isSaveExit) {
            this.backView();
          } 

          this.alreadySavedCustomerOrCustomerGroup = true;
        } else {
          let error = response.data.message;
          this.$toast.error(error.replaceAll('"', ""));
        }
      }
    },
    /**
     * Updates an existing customer or customer group and handles the API response.
     * @param {boolean} isSaveExit - Flag indicating whether to exit after the update.
     */
    async updateCustomerOrCustomerGroup(isSaveExit) {
      const isFormValid = await this.$refs.formValidator.validate();
      if (isFormValid) {
        let bodyPayload, apiEndPoint;

        if (this.isCustomerApi()) {
          apiEndPoint = API.API_ENDPOINT.customer;
          bodyPayload = {
            name: this.name,
            email: this.email
          };
        } else {
          let idList = this.selectedCustomerList.map(fieldItem => fieldItem.id);
          apiEndPoint = API.API_ENDPOINT.customerGroup;
          bodyPayload = {
            name: this.name,
            customers: idList
          };
        }

        let customerOrCustomerGroupId = this.recordId ? this.recordId : this.id;
        
        let response = await masterAPI(
          apiEndPoint,
          API.API_METHOD.put,
          customerOrCustomerGroupId,
          JSON.stringify(bodyPayload)
        );

        if (response.status == 200) {
          if (this.isCustomerApi()) {
            await this.assignCustomerGroup(customerOrCustomerGroupId);
            this.$toast.success("Customer update successfully");
          } else {
            this.$toast.success("Customer group update successfully");
          }

          if (isSaveExit) {
            this.backView();
          }
        } else {
          let error = response.data.message;
          this.$toast.error(error.replaceAll('"', ""));
        }
      }
    },
    /**
     * Assigns a customer to selected customer groups.
     * @param {number} customerId - The ID of the customer to be assigned to groups.
     * @returns {Promise<Array>} - Array of responses from the assign API calls.
     */
    async assignCustomerGroup(customerId) {
      let apiEndPoint =
        API.API_ENDPOINT.customer +
        "/" +
        customerId +
        "/" +
        API.API_ENDPOINT.assignCustomerGroup;

      // Create an array of promises for each API call
      const apiPromises = this.selectedCustomerGroupList.map(async group => {
        let bodyPayload = {
          customerGroupId: group.id
        };

        // Return the promise
        return masterAPI(
          apiEndPoint,
          API.API_METHOD.put,
          undefined,
          JSON.stringify(bodyPayload)
        );
      });

      // Use Promise.all to wait for all promises to be resolved
      const responses = await Promise.all(apiPromises);
      return responses;
    },
    selectCustomerGroup(event) {
      console.log(event);
    },
    selectedCustomer(event) {
      console.log(event);
    },
    /**
     * Navigates back to the previous view using the Vue Router.
     */
    backView() {
      this.$router.push({
        name: "viewallsection",
        params: { preselected: sectionList.customerOrCustomerGroup }
      });
    },
    /**
     * Checks if the selected option is 'Customer'.
     * @returns {boolean} - True if the selected option is 'Customer', false otherwise.
     */
    isCustomerApi() {
      return this.selectedOption === "Customer" ? true : false;
    },
    /**
     * Retrieves all customers and customer groups and initializes component data.
     */
    async getAllCustomersOrGroup() {
      this.customerList = await getAllCustomerOrCustomerGroupApi(false);
      this.customerGroupList = await getAllCustomerOrCustomerGroupApi(true);

      if (this.recordId && !this.isCustomerGroupView) {
        let matchingItems = this.customerList.filter(
          item => item.id === this.recordId
        );
        if (matchingItems.length > 0) {
          this.id = matchingItems[0].id;
          this.name = matchingItems[0].name;
          this.email = matchingItems[0].email;
          this.invited = matchingItems[0].invited;
          this.apiKey = matchingItems[0].apiKey;
          let customerId = matchingItems[0].id;
          this.selectedCustomerGroupList = this.customerGroupList.filter(
            group => group.customers.includes(customerId)
          );
        }
      }

      if (this.recordId && this.isCustomerGroupView) {
        let matchingItems = this.customerGroupList.filter(
          item => item.id === this.recordId
        );
        if (matchingItems.length > 0) {
          this.name = matchingItems[0].name;
          this.id = matchingItems[0].id;
          this.email = matchingItems[0].email;
          this.invited = matchingItems[0].invited;
          this.apiKey = matchingItems[0].apiKey;
          this.selectedCustomerList = filterCustomerObjectsById(
            this.customerList,
            matchingItems[0].customers
          );
        }
      }
    }
  },
  created() {
    if (this.$route.params.view) {
      if (this.$route.params.view.includes("edit-customer-group")) {
        this.selectedOption = this.dropdownOptionList[1].text;
      } else {
        this.selectedOption = this.dropdownOptionList[0].text;
      }
    }
    this.getAllCustomersOrGroup();
  }
};
</script>

<style></style>
