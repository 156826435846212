<template>
  <div>
    <div class="choices-dropdown-label">{{ label }}</div>
    <select ref="select" :disabled="isDisabled">
      <slot></slot>
    </select>
  </div>
</template>
<script>
import Choices from "choices.js";
import "choices.js/public/assets/styles/choices.min.css";  

export default {
  name: "selects",
  props: ["options", "value", "label", "isDisabled"],
  mounted: function() {
    this.choicesInstance = new Choices(this.$refs.select, {
      shouldSort: false
    });
    
    // Set initial value based on the 'value' prop
    this.setValueSelectDropdown();
    
    this.$refs.select.addEventListener("addItem", this.handleSelectChange);
    this.setChoices();
  },
  methods: {
    handleSelectChange(e) {
      this.$emit("input", e.target.innerText);
    },
    setChoices() {
      this.choicesInstance.setChoices(this.options, "id", "text", true);
    },
    setValueSelectDropdown() {      
      if (this.value) {
        this.choicesInstance.setValue([this.value]);
      }
    }
  },
  updated() {
    this.setChoices();
  },
  destroyed: function() {
    this.choicesInstance.destroy();
  }
};
</script>
<style></style>
