<template>
  <div>
    <loader :isLoader="showLoader"></loader>
    <div class="d-flex">
      <base-button
        type="primary"
        class="btn-theme-EFEBEB left-border btn-br1"
        @click="editDuplicateLaneView(item.id, true, 'editLane')"
      >
        <img src="../../assets/img/edit.png"/>
      </base-button>
      <base-button
        type="primary"
        class="btn-theme-EFEBEB table-action-button btn-br1"
        @click="editDuplicateLaneView(item.id, false, 'editLane')"
      >
        <img src="../../assets/img/duplicate.png"/>
      </base-button>
      <base-button
        type="primary"
        class="btn-theme-EFEBEB table-action-button right-border"
        @click="removeItem(item.id)"
      >
        <img src="../../assets/img/trash.png"/>
      </base-button>
    </div>
    
    <confirmation-modal 
      ref="confirmationModal"
      @delete="deleteLane" 
    />
  </div>
</template>
<script>
import { sectionList } from "@/helpers/utility";
import { API, masterAPI } from "@/api/API";
import Loader from "@/views/common/Loader.vue";
import ConfirmationModal from "@/views/common/ConfirmationModal.vue";

export default {
  name: "",
  components: {
    Loader,
    ConfirmationModal
  },
  props: {
    item: {
      type: Object,
    },
  },
  data: function() {
    return {
      showLoader: false,
      selectedLaneId: null,
    };
  },
  methods: {
    /**
     * Remove an item from the table.
     * @param {Object} item - The item to be removed.
     */
    async removeItem(item) {
      this.showDeleteConfirmationModal(item);
    },
    /**
     * Navigates to the edit or duplicate view based on the provided parameters.
     * @param {string} laneId - The ID of the lane.
     * @param {boolean} isEdit - Indicates whether the view is for editing or duplicating.
     * @param {string} screenName - The name of the screen/view to navigate to.
     */
    editDuplicateLaneView(laneId, isEdit, screenName) {
      let viewName = this.getLaneViewName(isEdit);
      this.$router.push({
        name: screenName,
        params: {
          view: viewName,
          id: laneId,
        },
      });
    },
    /**
     * Gets the view name based on the edit mode.
     * @param {boolean} editMode - Indicates whether the view is for editing.
     * @returns {string} - The view name ('edit' or 'duplicate').
     */
    getLaneViewName(editMode) {
      return editMode ? "edit" : "duplicate";
    },
    /**
     * Shows the delete confirmation modal for a selected record.
     * @param {string} selectedRecordId - The ID of the record to be deleted.
    */
    showDeleteConfirmationModal(selectedRecordId) {
      this.selectedLaneId = selectedRecordId;
      this.$refs.confirmationModal.showDeleteConfirmation();
    },
    /**
     * Asynchronously deletes a lane by its ID.
    */
    async deleteLane() {
      if (this.selectedLaneId) {
        this.showLoader = true;
        let response = await masterAPI(
          API.API_ENDPOINT.lane,
          API.API_METHOD.delete,
          this.selectedLaneId
        );
        this.showLoader = false;
        if (response.status == 200) {
          this.$toast.success("Lane deleted successfully");
          this.$emit("remove-item", sectionList.lane);
        }
      }
    },
  },
};
</script>
<style></style>
