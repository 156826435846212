<template>
  <div>
    <header class="header-global sidebar-large-view">
      <base-nav
        class="navbar-main headroom header-view"
        id="navbar-main"
        :type="navbarType ? navbarType : ''"
        :showMenuBtn="true"
        effect="dark"
        expand
      >
        <router-link slot="brand" class="mr-3 mt-1" to="/">
          <img class="" src="../assets/img/pallet-logo.png" alt="logo" />
        </router-link>
        
        <sidebar-menu
          :activeMenuName="activeMenuName"
        />

        <user-profile/>

      </base-nav>
    </header>

    <div class="sidebar-mobile-view">
        <button class="navbar-toggler navbar-dark sidebar-toggler-button" type="button" v-b-toggle.sidebar-backdrop>
            <i class="fa fa-bars" aria-hidden="true"></i>
        </button>

        <user-profile/>

        <b-sidebar id="sidebar-backdrop" bg-variant="white" backdrop shadow>
            <div class="px-3">
                <router-link slot="brand" class="mr-3 mt-1" to="/">
                  <img class="m-pallet-logo" src="../assets/img/pallet-logo.png" alt="logo" />
                </router-link>

                <sidebar-menu 
                  :activeMenuName="activeMenuName"
                />
            </div>
        </b-sidebar>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import SidebarMenu from "@/layout/SidebarMenu";
import UserProfile from "@/layout/UserProfile";
import BaseNav from "@/components/BaseNav";
import Headroom from "headroom.js";
import { BSidebar } from 'bootstrap-vue/esm/components/sidebar/sidebar';
import { VBToggle } from "bootstrap-vue/esm/directives/toggle/toggle";
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.directive('b-toggle', VBToggle)

export default {
  components: {
    SidebarMenu,
    UserProfile,
    BSidebar,
    BaseNav,
  },
  props: {
    navbarType: String,
    activeMenuName: {
      type: String,
    },
  },
  mounted: function() {
    let headroom = new Headroom(document.getElementById("navbar-main"), {
      offset: 300,
      tolerance: {
        up: 30,
        down: 30
      }
    });
    headroom.init();
  }
};
</script>
<style>
.navbar-main.headroom {
  z-index: 9999;
}

@media only screen and (max-width: 700px) {
  .pallet-text {
    display: none;
  }
}
</style>
