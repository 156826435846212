<template>
    <div>
        <app-header 
            v-if="!isCreate" 
            :activeMenuName="activeMenuName" 
        />
        <div 
            :class="!isCreate
                ? 'wrapper row tariff-app-container-height'
                : 'wrapper tariff-app-container-height'
        ">

            <div :class="!isCreate ? 'col-md-7 container-right-border' : ''">
                <div :class="!isCreate ? 'custom-padding' : ''">
                    <div v-if="!isCreate" class="mb-3">
                        <span class="back-text-btn" @click="backView">
                            Back
                        </span>
                    </div>
                    <ValidationObserver v-slot="{ validate }" ref="formValidator">
                        <form 
                            @submit.prevent="validate().then(saveBaseRate(false))" 
                            class="needs-validation"
                            id="add-qo"
                        >
                            <!-- <div class="row">
                                <div class="col-lg-5">
                                    <base-input
                                        type="text"
                                        label="Name"
                                        name="Name"
                                        rules="required"
                                        v-model="name"
                                    >
                                    </base-input>
                                </div>
                            </div> -->

                            <div class="row mb-4">
                                <div class="col-md-12 col-lg-5">
                                    <div class="create-section-text mb-2">
                                        Choose a Base Rate
                                    </div>
                                    <ValidationProvider rules="required" name="Base rate" v-slot="{ errors }">
                                        <choices-single 
                                            ref="choicesSingle"
                                            :options="baseRateList"
                                            :value="selectedBaseRate" 
                                            v-model="selectedBaseRate"
                                            @input="event => selectBaseRate(event)"
                                        >
                                            <option value="0">Select Base rate</option>
                                        </choices-single>
                                        <span class="validate-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>

                            <div v-if="selectedBaseRate">
                                <div class="row mb-2 mt-4">                
                                    <div class="col-md-5">
                                        <div class="create-section-text mb-2">
                                            Choose a Discount Type
                                        </div>
                                        <ValidationProvider rules="required" name="Discount Type" v-slot="{ errors }">
                                            <choices-single 
                                                :options="discountList"
                                                :value="selectedDiscountType"
                                                v-model="selectedDiscountType"
                                                @input="event => selectDiscount(event)"
                                            >
                                                <option value="0">Select</option>
                                            </choices-single>
                                        <span class="validate-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            
                                <!-- Weight discount form -->
                                <div v-if="selectedDiscountType === 'Weight'">
                                    <weight-discount-form 
                                        :weightDiscountItems="weightDiscountItems"
                                        @add-weight-discount="addWeightDiscount"
                                        @remove-weight-discount="removeWeightDiscount"
                                    />
                                </div>

                                <!-- Geography discount form -->
                                <div v-else-if="selectedDiscountType === 'Geography'">
                                    <geography-discount-form 
                                        :geographyDiscountItems="geographyDiscountItems"
                                        @add-geography-discount="addGeographyDiscount"
                                        @remove-geography-discount="removeGeographyDiscount"
                                    />
                                </div>
                            </div>

                            <button 
                                type="submit" 
                                class="btn mt-3 btn-default"
                            >
                              Save
                            </button>

                            <div>
                                <button 
                                    type="button" 
                                    class="btn mt-3 btn-default" 
                                    @click="saveBaseRate(true)"
                                >
                                 Save and Exit
                                </button>
                            </div>
                        </form>
                    </ValidationObserver>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import AppHeader from "@/layout/AppHeader.vue";
import ChoicesSingle from "@/components/SingleSelect";
import { API, masterAPI } from "@/api/API";
import WeightDiscountForm from "./WeightDiscountForm.vue";
import GeographyDiscountForm from "./GeographyDiscountForm.vue";

export default {
    bodyClass: "landing",
    components: {
        AppHeader,
        ChoicesSingle,
        WeightDiscountForm,
        GeographyDiscountForm
    },
    data: function () {
        return {
            // Data properties
            activeMenuName: "create-custom-fields",
            baseRateList: [],
            name:"",
            selectedBaseRate: null,
            baseRateInvalid: false,
            discount: "",
            alreadySavedBaseRate: false,
            id: "",
            selectedDiscountType: null,
            discountList: [
                {
                    id: 1,
                    text: "Geography"
                },
                {
                    id: 2,
                    text: "Weight"
                },
                {
                    id: 3,
                    text: "Entire Tariff"
                }
            ],
            weightDiscountItems: [
                { 
                    from: "", 
                    to: "", 
                    discount: "", 
                    minimum: "" 
                } 
            ],
            geographyDiscountItems: [
                { 
                    from: [], 
                    to: [], 
                    discount: "", 
                    minimum: "", 
                    between: "notBetween",
                    fromRadial: "zip",
                    toRadial: "state",
                } 
            ]
        };
    },
    props: {
        // Component props
        moduleView: {
            type: String
        },
        isCreate: {
            type: Boolean
        }
    },
    computed: {
        // Computed property for recordId
        recordId() {
            return +this.$route.params.id;
        }
    },
    methods: {
        /**
        * Get all baserates from the API and populate the baseRateList.
        */
        async getBaseRates() {
            // Make API call to fetch baserate data
            let response = await masterAPI(
                API.API_ENDPOINT.baseRate,
                API.API_METHOD.get,
                undefined,
                undefined
            );

            if (response.status == 200) {
                let baseRateData = response.data;
                this.baseRateList = baseRateData.map((baseRate, index) => ({
                    id: index,
                    text: baseRate.name,
                }));
            }

            this.getClassDiscount();
        },
        selectBaseRate(event) {
            this.selectedBaseRate = event;
            this.baseRateInvalid = false;
        },
        selectDiscount(event) {
            this.selectedDiscountType = event;

            // Reset the data based on the selected discount type
            if (this.selectedDiscountType === 'Weight') {
                // Reset the geography discount items if switching to Weight discount
                this.geographyDiscountItems = [
                    { 
                        from: [], 
                        to: [], 
                        discount: "", 
                        minimum: "", 
                        between: "notBetween",
                        fromRadial: "zip",
                        toRadial: "state",
                    } 
                ];
            } else if (this.selectedDiscountType === 'Geography') {
                // Reset the weight discount items if switching to Geography discount
                this.weightDiscountItems = [
                    { 
                        from: "", 
                        to: "", 
                        discount: "", 
                        minimum: "" 
                    } 
                ];
            }
        },
        /**
        * Adds a new weight discount item to the list
        */
        async addWeightDiscount() {
            const isFormValid = await this.checkFormValidator();
            if(isFormValid) {
                this.weightDiscountItems.push({ 
                    from: "", 
                    to: "", 
                    discount: "", 
                    minimum: "" 
                });
            }
        },
        /**
        * Remove a weight discount from the weight discount list
        * @param {number} index - The index of the weight discount to remove
        */
        removeWeightDiscount(index) {
            this.weightDiscountItems.splice(index, 1);
        },
        /**
        * Remove a geography discount from the geography discount list
        * @param {number} index - The index of the geography discount to remove
        */
        removeGeographyDiscount(index) {
            this.geographyDiscountItems.splice(index, 1);
        },
        /**
        * Adds a new geography discount item to the list
        */
        async addGeographyDiscount() {
            const isFormValid = await this.checkFormValidator();
            if(isFormValid) {
                this.geographyDiscountItems.push({ 
                    from: [], 
                    to: [], 
                    discount: "", 
                    minimum: "",  
                    between: "notBetween", 
                    fromRadial: "zip", 
                    toRadial: "state" 
                });
            }
        },
        /**
         * Navigates back to the previous view using the Vue Router.
         */
        backView() {
            this.$router.push({
                name: "viewallsection",
                params: { preselected: this.moduleView }
            });
        },
        /**
         * Performs form validation using the formValidator component.
         * @returns {Promise<boolean>} - A promise that resolves to true if the form is valid, false otherwise.
         */
        async checkFormValidator() {
            return await this.$refs.formValidator.validate();
        },
        /**
         * Save the base rate, either creating or updating a class discount based on the context.
         * @param {boolean} isSaveExit - Flag to determine if the view should exit after saving.
        */
        saveBaseRate(isSaveExit) {
            if (this.isCreate) {
                if (this.alreadySavedBaseRate) {
                    this.updateClassDiscount(isSaveExit);
                } else {
                    this.createClassDiscount(isSaveExit);
                }
            } else {
                this.updateClassDiscount(isSaveExit);
            }
        },
        /**
         * Create a new class discount if the form is valid.
         * @param {boolean} isSaveExit - Flag to determine if the view should exit after saving.
        */
        async createClassDiscount(isSaveExit) {
            // Perform form validation
            const isFormValid = await this.checkFormValidator();
            
            if (isFormValid) {
                // console.log("Selected Discount Type: ", this.selectedDiscountType);
                // console.log("Weight Discount Items: ", this.weightDiscountItems);
                // console.log("Geography Discount Items: ", this.geographyDiscountItems);

                // Prepare payload for API request
                let bodyPayload = JSON.stringify({
                    //name: this.name,
                    baseRate: this.selectedBaseRate,
                    discount: this.discount,
                });

                // Make an API request to create a new class discount
                let response = await masterAPI(
                    API.API_ENDPOINT.classDiscount,
                    API.API_METHOD.post,
                    undefined,
                    bodyPayload
                );

                if (response.status == 200) {
                    if (this.isCreate) {
                        this.alreadySavedBaseRate = true;
                        this.id = response.data.id;
                        this.$toast.success("BaseRate created successfully");
                    } else {
                        this.$toast.success("BaseRate duplicate successfully");
                    }

                    if (isSaveExit) {
                        this.backView();
                    }
                } else {
                    let error = response.data.message;
                    this.$toast.error(error.replaceAll('"', ""));
                }
            }
        },
        /**
         * Update the class discount if the form is valid.
         * @param {boolean} isSaveExit - Flag to determine if the view should exit after saving.
        */
        async updateClassDiscount(isSaveExit) {
            const isFormValid = await this.checkFormValidator();
            
            if (isFormValid) {
                let bodyPayload = JSON.stringify({
                    //name: this.name,
                    baseRate: this.selectedBaseRate,
                    discount: this.discount,
                });
                
                let baseRateId = this.recordId ? this.recordId : this.id;

                let response = await masterAPI(
                    API.API_ENDPOINT.classDiscount,
                    API.API_METHOD.put,
                    baseRateId,
                    bodyPayload
                );

                if (response.status == 200) {
                    this.$toast.success("BaseRate update successfully");

                    if (isSaveExit) {
                        this.backView();
                    }
                } else {
                    let error = response.data.message;
                    this.$toast.error(error.replaceAll('"', ""));
                }
            }
        },
        /**
         * Retrieve the class discount data based on the record ID.
        */
        async getClassDiscount() {
            if (this.recordId) {
                let response = await masterAPI(
                    API.API_ENDPOINT.classDiscount,
                    API.API_METHOD.get,
                    this.recordId,
                    undefined
                );

                if (response.status == 200) {
                    let responseData = response.data;
                    this.selectedBaseRate = responseData.name;
                    this.discount = responseData.discount;
                    
                    this.$nextTick(() => {
                        this.$refs.choicesSingle.setValueSelectDropdown();
                    });
                }
            }
        },
    },
    created() {
        this.getBaseRates();
    },
}
</script>

<style></style>