import { render, staticRenderFns } from "./TariffView.vue?vue&type=template&id=6de4a948&scoped=true&"
import script from "./TariffView.vue?vue&type=script&lang=js&"
export * from "./TariffView.vue?vue&type=script&lang=js&"
import style0 from "./TariffView.vue?vue&type=style&index=0&id=6de4a948&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6de4a948",
  null
  
)

export default component.exports