<template>
  <div>
    <v-app id="inspire">
      <v-data-table
        :headers="headers"
        :items="groupedTableData"
        :expanded.sync="expandedItems"
        item-key="id"
        :hide-default-footer="true"
        class="elevation-1"
        :items-per-page="tableData.length"
        :mobile-breakpoint="0"
      >
        <template v-slot:item.name="{ item }">
          <div v-if="item.action">
            <v-icon
              @click="toggleLaneRow(item)"
              :class="[
                'expand-icon',
                expandedItems.includes(item)
                  ? 'mdi-chevron-up'
                  : 'mdi-chevron-down'
              ]"
            >
              {{
                expandedItems.includes(item)
                  ? "mdi-chevron-up"
                  : "mdi-chevron-down"
              }}
            </v-icon>
          </div>
          <div v-else>
            {{ item.name }}
          </div>
        </template>

        <template v-slot:item.origin="{ item }">
          <origin-destination-dropdown
            :name="item.origin"
            :options="item.subCity.origin"
          />
        </template>

        <template v-slot:item.destination="{ item }">
          <origin-destination-dropdown
            :name="item.destination"
            :options="item.subCity.destination"
          />
        </template>

        <template v-slot:item.pricingMethod="{ item }">
          {{ item.pricingMethodTypeName }}
        </template>

        <template v-slot:item.value="{ item }">
          <price-value-dropdown :item="item" />
        </template>

        <template v-slot:item.action="{ item }">
          <div v-if="item.action"></div>
          <div v-else>
            <lane-action-button :item="item" @remove-item="removeItem" />
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="sub-lane-data-table">
            <v-simple-table>
              <template v-slot:default>
                <div v-if="item.children">
                  <lane-table
                    :tableData="item.children"
                    :isTariff="false"
                    :isParentView="true"
                    @remove-item="removeItem"
                  />
                </div>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-app>
  </div>
</template>

<script>
import OriginDestinationDropdown from "@/views/common/OriginDestinationDropdown.vue";
import PriceValueDropdown from "@/views/common/PriceValueDropdown.vue";
import LaneActionButton from "@/views/common/LaneActionButton.vue";
import LaneTable from "@/views/common/LaneTable.vue";

export default {
  bodyClass: "landing",
  components: {
    OriginDestinationDropdown,
    PriceValueDropdown,
    LaneActionButton,
    LaneTable
  },
  data: function() {
    return {
      // Component's data properties
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Origin", value: "origin", sortable: false },
        { text: "Destination", value: "destination", sortable: false },
        { text: "Pricing Method", value: "pricingMethod", sortable: false },
        { text: "Value", value: "value", sortable: false },
        { text: "Created On", value: "createdAt", sortable: false },
        { text: "Action", value: "action", sortable: false }
      ],
      expandedItems: []
    };
  },
  props: {
    // Component props
    tableData: {
      type: Array
    }
  },
  computed: {
    // Compute and return grouped table data using the groupedLaneItems method
    groupedTableData() {
      return this.groupedLaneItems(this.tableData);
    }
  },
  methods: {
    /**
     * Toggle the expansion state of a table row.
     * @param {Object} item - The item to be toggled.
     */
    toggleLaneRow(item) {
      const index = this.expandedItems.indexOf(item);
      if (index === -1) {
        this.expandedItems.push(item);
      } else {
        this.expandedItems.splice(index, 1);
      }
    },
    /**
     * Remove an item from the table.
     * @param {Object} item - The item to be removed.
     */
    async removeItem(item) {
      this.$emit("remove-item", item);
    },
    /**
     * Group items in the table data by origin and destination.
     * @param {Array} items - The items to be grouped.
     * @returns {Array} - Grouped data.
     */
    groupedLaneItems(items) {
      const groupedData = {};

      // Iterate through the data and group by origin and destination
      items.forEach(item => {
        const key = `${item.origin} to ${item.destination}`;
        if (!groupedData[key]) {
          groupedData[key] = {
            ...item,
            label: key,
            children: [],
            expanded: false
          };
        }

        groupedData[key].children.push(item);
      });

      // Convert the groupedData object into an array
      const laneGroupedData = Object.values(groupedData);

      laneGroupedData.forEach(entry => {
        if (entry.children && entry.children.length === 1) {
          // Only one record with the same origin and destination, remove the "children" key
          delete entry.children;
        }

        if (entry.children && entry.children.length > 1) {
          entry.name = "";
          entry.createdAt = "";
          entry.pricingMethod = "";
          entry.subCity = {
            origin: [],
            destination: []
          };
          entry.value = null;
          entry.action = true;
          entry.pricingMethodTypeName = "";
        }
      });
      return laneGroupedData;
    }
  }
};
</script>

<style>
.expand-icon {
  cursor: pointer;
}
</style>
