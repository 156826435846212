<template>
  <div>
    <customer-form
      :isCreate="false"
    />
  </div>
</template>

<script>
import CustomerForm from "@/views/customers/CustomerForm.vue";

export default {
  bodyClass: "landing",
  components: {
    CustomerForm,
  },
  data: function() {
    return {
      
    };
  },
};
</script>

<style></style>
