<template>
  <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.49686 9.9992L5.70903 6.1446C6.85521 4.76913 8.40985 3.99649 10.0308 3.9967V3.9967C12.5297 3.99692 14.7767 5.82254 15.7049 8.60662L15.8357 8.99879" :stroke="isBetween ? '#323232' : '#CED4DA'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.83158 9.9991H2.49686V5.99744" :stroke="isBetween ? '#323232' : '#CED4DA'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.5031 14.0009L14.2909 17.8555C13.1447 19.2309 11.5901 20.0036 9.96913 20.0034V20.0034C7.4703 20.0031 5.22326 18.1775 4.2951 15.3934L4.16422 15.0013" :stroke="isBetween ? '#323232' : '#CED4DA'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M14.1684 14.0009H17.5031V18.0025" :stroke="isBetween ? '#323232' : '#CED4DA'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>
<script>
export default {
  props: {
    isBetween: {
      type: Boolean,
    },
  },
};
</script>
<style></style>
