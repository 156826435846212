<template>
  <div>
    <loader :isLoader="showLoader"></loader>
    <modal :show.sync="isModal" modal-classes="modal-dialog-centered modal-xl">
      <slot name="close-button">
        <button type="button" class="modal-close" @click="isModal = false" data-dismiss="modal" aria-label="Close">
          <span :aria-hidden="true">×</span>
        </button>
      </slot>

      <div class="mt-4">
        <div>
          <div class="row align-items-center mt-3">
            <div class="table-title-ml">
              <span class="v-data-table-title">
                All Tariffs
              </span>
            </div>
            <div class="col-md-3">
              <vue-input 
                alternative 
                placeholder="Search Tariffs" 
                type="text" 
                addonLeftIcon="ni ni-zoom-split-in"
                class="round-search-input search-all-quote" 
                v-model="searchVersionsTariff"
                @input="(event) => searchTariffVersionsByName(event)"
              />
            </div>
            <div class="col-md-3">
              <div class="select-section-title">Created On</div>
              <date-picker 
                placeholder="Select date" 
                class="tariff-date-picker" 
                format="MMM DD, YYYY"
                v-model="selectedDates" 
                @input="filterTariffVersions" 
                :disabled-date="(date) => date >= new Date()"
                range
              />
            </div>
          </div>
        </div>
        <div class="tariff-name mb-1">
          {{selectedParentTariff ? selectedParentTariff.name : ''}}
        </div>
        <div class="view-tariff-version-tables">
          <v-app id="inspire">
            <v-data-table 
              v-model="selected" 
              :headers="headers" 
              :items="tariffVersionsList" 
              :single-select="singleSelect"
              :hide-default-footer="true" 
              item-key="name" 
              select-enable class="elevation-1"
              :items-per-page="tariffVersionsList.length" 
              :mobile-breakpoint="0"
            >
            
            <template v-slot:item.createdAt="{ item }">
              <date-format
                :date="item.createdAt"
              />
            </template>

            <template v-slot:item.updatedAt="{ item }">
              <date-format
                :date="item.updatedAt"
              />
            </template>

            <template v-slot:item.applicableTo="{ item }">
              <el-dropdown 
                v-if="item.sectionDetail.customerOrCustomerGroup.length > 0"
                trigger="click"
              >
                <a class="btn-link view-all-link-btn mb-2">
                  View
                </a>
                <el-dropdown-menu slot="dropdown">
                  <div 
                    class="mb-3"
                    v-if="checkCustomerGroupExists(item.sectionDetail.customerOrCustomerGroup).length > 0"
                  >
                    <div class="font-bold text-transform-capitalize font-size-13">
                      Customer Groups
                    </div>
                    <div 
                      v-for="(customerGroup, index) in checkCustomerGroupExists(item.sectionDetail.customerOrCustomerGroup)" 
                      :key="index" 
                      class="row"
                    >
                      <div class="font-size-13 col-12 mt-1 c-p">
                        <span @click="toggleCustomer(customerGroup)" class="expanded">
                          <i 
                            v-if="customerGroup.children" 
                            class="toggle-icon" 
                            :class="{'fa fa-caret-down': customerGroup.expanded, 'fa fa-caret-right': !customerGroup.expanded}">
                          </i>
                        </span>
                        &nbsp;
                        <span @click="toggleCustomer(customerGroup)">
                          {{ customerGroup.name }}
                        </span>
                      </div>

                      <div 
                        v-if="customerGroup.children && customerGroup.expanded" 
                        class="font-size-13 col-12 mt-1"
                      >
                        <div 
                          v-for="(customer, customerIndex) in customerGroup.children" :key="customerIndex"
                        >
                          <div class="font-size-13 col-12 mt-1">
                            {{customer.name}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="checkCustomersExists(item.sectionDetail.customerOrCustomerGroup).length > 0">
                    <div class="font-bold text-transform-capitalize font-size-13">
                      Customers
                    </div>
                    <div 
                      v-for="(customer, index) in checkCustomersExists(item.sectionDetail.customerOrCustomerGroup)" 
                      :key="index" 
                      class="row"
                    >
                      <div class="font-size-13 col-12 mt-1">
                        {{customer.name}}
                      </div>
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </template>

            <template v-slot:item.action="{ item }">
              <div class="c-p" @click="viewTariffVersion(item)">
                <svg width="30" height="14" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M38.937 11.4408C37.9356 13.6139 35.3653 16.3159 31.8808 18.5311C28.2551 20.836 23.8146 22.5 19.5 22.5C15.2105 22.5 11.0328 20.7293 7.65578 18.368C4.49397 16.1571 2.15509 13.5143 1.12048 11.5219C2.35866 9.31979 4.82341 6.76911 8.05574 4.71953C11.4847 2.54526 15.6763 1 20 1C24.3497 1 28.7999 2.43673 32.3431 4.5467C35.7652 6.58455 38.1625 9.13883 38.937 11.4408Z" stroke="black" stroke-width="2"/>
                  <path d="M28.5 12C28.5 17.3432 24.6026 21.5 20 21.5C15.3974 21.5 11.5 17.3432 11.5 12C11.5 6.65678 15.3974 2.5 20 2.5C24.6026 2.5 28.5 6.65678 28.5 12Z" stroke="black" stroke-width="2"/>
                  <circle cx="20" cy="12" r="5.5" fill="black"/>
                  <circle cx="17.5" cy="8.5" r="2" fill="white"/>
                </svg>
              </div>
            </template>
            </v-data-table>
          </v-app>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DateFormat from "@/views/common/DateFormat.vue";
import Loader from "@/views/common/Loader.vue";
import { API, masterAPI } from "@/api/API";
import { setDateFormat } from "@/helpers/utility";
import DatePicker from 'vue2-datepicker';
import { Dropdown, DropdownItem, DropdownMenu } from "element-ui";
import moment from "moment";
import 'vue2-datepicker/index.css';

export default {
  components: {
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    Modal,
    DatePicker,
    DateFormat,
    Loader
  },
  data: function () {
    return {
      isModal: false, // Boolean to control the visibility of the modal
      searchVersionsTariff: "",
      singleSelect: false,
      selected: [],
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Created On", value: "createdAt", sortable: false },
        { text: "Last Altered", value: "updatedAt", sortable: false },
        { text: "Applicable to", value: "applicableTo", sortable: false },
        { text: "View Full Tariff", value: "action", class: "custom-th-class", sortable: false},
      ],
      tariffVersionsList: [],
      tariffVersionsOldList: [],
      date: moment().format("MMM DD, YYYY"),
      selectedDates: [],
      selectedParentTariff: null,
      showLoader: false,
    };
  },
  methods: {
    /**
     * Opens the modal and sets the tariff versions.
     * @param {Object} selectedTariff - The selected tariff object.
    */
    async openModal(selectedTariff) {
      this.selectedParentTariff = selectedTariff;
      this.isModal = true;
      this.listTariffVersions();
    },
    /**
     * Fetches and lists tariff versions.
    */
    async listTariffVersions() {
      this.showLoader = true;
      let tariffId = this.selectedParentTariff.id;

      let endPoint = API.API_ENDPOINT.tariff + "/" + tariffId + "/" + API.API_ENDPOINT.tariffVersion;

      let response = await masterAPI(
        endPoint,
        API.API_METHOD.get,
        undefined,
        undefined
      );

      this.showLoader = false;
      if (response.status == 200) {
        this.tariffVersionsList = response.data;
        this.tariffVersionsOldList = response.data;
      }
    },
    /**
     * Filters tariff versions by name based on search value.
     * @param {string} searchValue - The search value for filtering tariff versions.
    */
    searchTariffVersionsByName(searchValue) {
      const searchLower = searchValue.toLowerCase();

      this.tariffVersionsList = this.tariffVersionsOldList
        .filter(filterItem => 
          filterItem.name.toLowerCase().includes(searchLower)
        );
    },
    /**
     * Filters tariff versions based on selected date range.
    */
    filterTariffVersions() {
      if (this.selectedDates.length > 0 && this.selectedDates[0]) {
        const [startDate, endDate] = this.selectedDates.map(date => moment(date));
        
        this.tariffVersionsList = this.tariffVersionsOldList.filter(tariffObj => {
          let createdAt = setDateFormat(tariffObj.createdAt);
          const tariffDate = moment(createdAt, "MMM Do, YYYY").toDate();
          return moment(tariffDate).isBetween(startDate, endDate, 'day', '[]');
        });
      } else {
        this.tariffVersionsList = this.tariffVersionsOldList;
      }
    },
    /**
     * Emits an event to view a specific tariff version and closes the modal.
     * @param {Object} item - The selected tariff version object.
    */
    viewTariffVersion(item) {
      this.$emit("view-tariff-version", item);
      this.isModal = false;
    },
    /**
     * Toggles the 'expanded' property of a node if it has children.
     *
     * @param {Object} node - The node to be toggled.
    */
    toggleCustomer(node) {
      if (node.children) {
        node.expanded = !node.expanded;
      }
    },
    /**
     * Checks for the existence of customers with customerId in the given list.
     *
     * @param {Array} customerList - The list of customers to check.
     * @returns {Array} - Filtered list containing only items with customerId.
    */
    checkCustomersExists(customerList) {
      return customerList.filter(item => item.customerId);
    },
    /**
     * Checks for the existence of customer groups with customerGroupId in the given list.
     *
     * @param {Array} customerGroupList - The list of customer groups to check.
     * @returns {Array} - Filtered list containing only items with customerGroupId.
    */
    checkCustomerGroupExists(customerGroupList) {
      return customerGroupList.filter(item => item.customerGroupId);
    },
  }
};
</script>
<style></style>
