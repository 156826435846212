import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import DashboardPlugin from "./plugins/dashboard-plugin";
import Vuetify from "vuetify";
import VueBasicAutocomplete from "vue-basic-autocomplete";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import store from './store';

Vue.config.productionTip = false;
Vue.use(DashboardPlugin);
Vue.use(Vuetify);
Vue.use(Toast);
Vue.use(VueBasicAutocomplete);

export const bus = new Vue();

new Vue({
  router,
  vuetify: new Vuetify(),
  store,
  render: h => h(App)
}).$mount("#app");
