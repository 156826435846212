<template>
  <div>
    <div class="mt-3" v-if="minInputAvailable">
      <div class="row mb-3">
        <base-checkbox
          class="mb-2 min-max-checkbox col-12 col-md-5 col-lg-3 "
          v-model="showMinInput"
          :checked="showMinInput"
        >
          Set Minimum charge
        </base-checkbox>
        <span class="data-type-input left price-charge-textBox" v-if="showMinInput">
          <input
            type="number"
            step="any"
            class="form-control"
            placeholder="00.00"
            v-model="minValue"
            @input="changeValue('minValue')"
            @blur="decimalValueValidation('minValue')"
          />
          <div v-if="minValueError != ''" class="validate-error">
            {{ minValueError }}
          </div>
        </span>
      </div>
    </div>
    <div class="mt-3" v-if="maxInputAvailable">
      <div class="row mb-3">
        <base-checkbox
          class="mb-2 min-max-checkbox  col-12 col-md-5 col-lg-3  "
          v-model="showMaxInput"
          :checked="showMaxInput"
        >
          Set Maximum charge
        </base-checkbox>
        <span class="data-type-input left price-charge-textBox" v-if="showMaxInput">
          <input
            type="number"
            step="any"
            class="form-control"
            placeholder="00.00"
            v-model="maxValue"
            @input="changeValue('maxValue')"
            @blur="decimalValueValidation('maxValue')"
          />
          <div v-if="maxValueError !== ''" class="validate-error">
            {{ maxValueError }}
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "loader",
  props: {
    minInputAvailable: {
      type: Boolean,
      default: true
    },
    maxInputAvailable: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      showMinInput: false,
      showMaxInput: false,
      minValue: "",
      maxValue: "",
      minValueError: "",
      maxValueError: ""
    };
  },
  methods: {
    /**
     * Change the value by truncating to 2 decimal places.
     *
     * @param {string} params - The parameter to be changed.
     */
    changeValue(params) {
      let fixedValue = this[params];
      if (fixedValue.includes(".")) {
        const parts = fixedValue.split(".");
        if (parts[1] && parts[1].length > 2) {
          this[params] = `${parts[0]}.${parts[1].substr(0, 2)}`;
        }
      }
      this.minMaxValueValidation();
    },
    /**
     * Validate and add decimal places if missing.
     *
     * @param {string} params - The parameter to be validated.
     */
    decimalValueValidation(params) {
      let fixedValue = this[params];
      if (fixedValue != "" && !fixedValue.includes(".")) {
        this[params] = fixedValue + ".00";
      }
      this.minMaxValueValidation();
    },
    /**
     * Validates the minimum and maximum charge values.
     * Sets corresponding error messages if validation fails.
     */
    minMaxValueValidation() {
      this.minValueError = "";
      this.maxValueError = "";

      if (this.showMinInput && this.minValue === "") {
        this.minValueError = "The Minimum charge field is required";
      }

      if (this.showMaxInput && this.maxValue === "") {
        this.maxValueError = "The Maximum charge field is required";
      }

      if (this.minValue !== "" && this.maxValue !== "") {
        if (parseFloat(this.maxValue) <= parseFloat(this.minValue)) {
          this.maxValueError =
            "Maximum charge value must be greater than minimum value";
        } else if (parseFloat(this.minValue) >= parseFloat(this.maxValue)) {
          this.minValueError =
            "Minimum charge value must be less than maximum value";
        }
      }

      if (!this.showMinInput) {
        this.minValue = "";
      }

      if (!this.showMaxInput) {
        this.maxValue = "";
      }
    },
    /**
     * Checks if the form passes the minMaxValueValidation.
     * Returns true if the form is valid, otherwise false.
     * @returns {boolean} - Indicates whether the form is valid.
     */
    checkMinMaxValueValidation() {
      this.minMaxValueValidation();
      let isValid = true;
      if (this.minValueError !== "") {
        isValid = false;
      } else if (this.maxValueError !== "") {
        isValid = false;
      }
      return isValid;
    }
  }
};
</script>
<style></style>
