<template>
    <div>
        <div 
            v-for="(item, index) in geographyDiscountItems" :key="index"
            class="row mb-2 mt-4" 
        >
            <div class="col-md-12 col-lg-8">
                <div class="row">
                    <div class="col-md-6 col-lg-3 pr-0">
                        <div class="titles-geography mb-2">
                            From
                        </div>

                        <ValidationProvider rules="required" name="from" v-slot="{ errors }">
                            <multiselect
                                placeholder="Select"
                                class="geography-tags"
                                :multiple="true"
                                v-model="item.from"
                                :options="geographyFromList"
                                :close-on-select="true"
                                :clear-on-select="true"
                                label="name"
                                track-by="name"
                            >
                            </multiselect>
                            <span class="validate-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                        
                        <div class="d-flex mt-3">
                            <div 
                                v-for="(option, index) in geographyRadioFromOptions" 
                                :key="index" 
                                class="geography-radios"
                            >
                                <base-radio 
                                    :name="option.name" 
                                    class="mb-2" 
                                    v-model="item.fromRadial"
                                >
                                </base-radio>
                                <p>{{ option.label }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 pr-0">
                        <div class="titles-geography mb-2">
                            To
                        </div>
                        <ValidationProvider rules="required" name="to" v-slot="{ errors }">
                            <multiselect
                                placeholder="Select"
                                class="geography-tags"
                                :multiple="true"
                                v-model="item.to"
                                :options="geographyToList"
                                :close-on-select="true"
                                :clear-on-select="true"
                                label="name"
                                track-by="name"
                            >
                            </multiselect>
                            <span class="validate-error">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <div class="d-flex mt-3">
                            <div 
                                v-for="(option, index) in geographyRadioToOptions" 
                                :key="index" 
                                class="geography-radios"
                            >
                                <base-radio 
                                    :name="option.name" 
                                    class="mb-2" 
                                    v-model="item.toRadial"
                                >
                                </base-radio>
                                <p>{{ option.label }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-3 pr-0">
                        <base-input 
                            type="number" 
                            label="Discount" 
                            step="any" 
                            class="mb-0" 
                            placeholder="%"
                            name="discount" 
                            v-model="item.discount" 
                            rules="required"
                        >
                        </base-input>
                    </div>

                    <div class="col-md-6 col-lg-3 pr-0">
                        <base-input 
                            type="number" 
                            label="Minimum" 
                            step="any" 
                            class="mb-0" 
                            name="minimum"
                            v-model="item.minimum" 
                            rules="required"
                        >
                        </base-input>
                    </div>
                </div>
            </div>

            <div class="col-md-12 col-lg-4 pr-0">
                <div class="row">
                    <div class="col-md-5 pr-0 d-flex pr-0 geography-between">
                        <base-radio 
                            name="notBetween" 
                            class="mb-2 text-nowrap discount-between" 
                            v-model="item.between"
                        >
                            Not Between
                        </base-radio>
                    </div>
                    <div class="col-md-5 pr-0 d-flex geography-between">
                        <base-radio 
                            name="between" 
                            class="mb-2 discount-between" 
                            v-model="item.between"
                        >
                            Between
                        </base-radio>
                    </div>
                    <div class="col-md-1 mt-4 mt-md-0 text-md-right">
                        <i
                           v-if="geographyDiscountItems.length > 1"
                           class="fa fa-trash unit-price-delete"
                           @click="handleRemoveGeography(index)"  
                        >
                        </i>
                    </div>
                </div>
            </div>

        </div>

        <div class="row mb-2 mt-2">
            <div class="col-md-12 col-lg-6">
                <button 
                    type="button" 
                    class="btn mt-3 btn-default" 
                    @click="handleAddNewGeography"
                >
                    <span>
                        <img src="../../assets/img/plus-active.svg" class="ml--2 mr-1" />
                    </span>
                    Add additional geography discount
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";

export default {
    bodyClass: "landing",
    components: {
        Multiselect
    },
    data: function () {
        return {
            geographyRadioFromOptions: [
                { name: 'zip', label: 'Zip' },
                { name: 'state', label: 'State' },
                { name: 'country', label: 'Country' }
            ],
            geographyRadioToOptions: [
                { name: 'zip', label: 'Zip' },
                { name: 'state', label: 'State' },
                { name: 'country', label: 'Country' }
            ],
            geographyFromList: [
                {
                    id: 1,
                    name: "NY"
                },
                {
                    id: 2,
                    name: "CT"
                },
                {
                    id: 3,
                    name: "MA"
                },
                {
                    id: 4,
                    name: "PA"
                }
            ],
            geographyToList: [
                {
                    id: 1,
                    name: "FL"
                },
                {
                    id: 2,
                    name: "CT"
                },
                {
                    id: 3,
                    name: "MA"
                },
                {
                    id: 4,
                    name: "PA"
                }
            ],
        }
    },
    props: {
        geographyDiscountItems: {
            type: Array
        }
    },
    methods: {
        handleAddNewGeography() {
            this.$emit('add-geography-discount');
        },
        handleRemoveGeography(index) {
            this.$emit('remove-geography-discount', index)
        }
    }
}
</script>

<style>
.discount-between label {
    font-size: 13px !important;
    padding-top: 2px;
}

.geography-between {
    margin-top: 40px
}
  
.geography-radios {
    text-align: center;
    margin: auto;
}

.geography-radios p {
    font-size: 13px;
    font-weight: 600;
}

.geography-radios .custom-control-label::before,
.geography-radios .custom-control-label::after {
    left: -25px;
}

.titles-geography {
    font-size: 14px;
}
</style>