<template>
  <div>
    <div class="mt-3" v-if="palletMaximumAvailable">
      <div class="row mb-3">
        <base-checkbox
          class="mb-2 additional-checkbox col-12 col-md-5 col-lg-3"
          v-model="showPalletMaximumInput"
          :checked="showPalletMaximumInput"
        >  
          Set Pallet Maximum Weight
        </base-checkbox>
        <span class=" col-12 col-md-5 col-lg-3 data-type-input lane-pallet-weight" v-if="showPalletMaximumInput">
          <input
            type="number"
            step="any"
            class="form-control"
            placeholder="Weight in lbs"
            v-model="palletMaximumValue"
            @input="changeValue('additionalValue')"
          />
          <div v-if="palletMaximumError !== ''" class="validate-error">
            {{ palletMaximumError }}
          </div>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "loader",
  props: {
    palletMaximumAvailable: {
      type: Boolean,
      default: true
    }
  },
  data: function() {
    return {
      showPalletMaximumInput: false,
      palletMaximumValue: "",
      palletMaximumError: ""
    };
  },
  methods: {
    /**
     * Change the value by truncating to 2 decimal places.
     *
     * @param {string} params - The parameter to be changed.
     */
    changeValue(params) {
      let fixedValue = this[params];
      if (fixedValue.includes(".")) {
        const parts = fixedValue.split(".");
        if (parts[1] && parts[1].length > 2) {
          this[params] = `${parts[0]}.${parts[1].substr(0, 2)}`;
        }
      }
      this.palletMaximumValidation();
    },
    /**
     * Validates the charge values.
     * Sets corresponding error messages if validation fails.
     */
    palletMaximumValidation() {
      this.palletMaximumError = "";

      if (this.showPalletMaximumInput && this.palletMaximumValue === "") {
        this.palletMaximumError =
          "The Additional Fixed charge field is required";
      }

      if (!this.showPalletMaximumInput) {
        this.palletMaximumValue = "";
      }
    },
    /**
     * Checks if the form passes the additionalValueValidation.
     * Returns true if the form is valid, otherwise false.
     * @returns {boolean} - Indicates whether the form is valid.
     */
    checkPalletMaximumValidation() {
      this.palletMaximumValidation();
      let isValid = true;
      if (this.palletMaximumError !== "") {
        isValid = false;
      }
      return isValid;
    }
  }
};
</script>
<style></style>
