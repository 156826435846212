<template>
  <div>
    <v-app id="inspire">
      <v-data-table
        :headers="headers"
        :items="tableData"
        :hide-default-footer="true"
        class="elevation-1"
        :items-per-page="tableData.length"
        :mobile-breakpoint="0"
      >
      <template v-slot:item.origin="{ item }">
          <origin-destination-dropdown
            :name="item.origin"
            :options="item.subCity.origin"
          />
        </template>

        <template v-slot:item.destination="{ item }">
          <origin-destination-dropdown
            :name="item.destination"
            :options="item.subCity.destination"
          />
        </template>

        <template v-slot:item.pricingMethod="{ item }">
          {{ item.pricingMethodTypeName }}
        </template>

        <template v-slot:item.value="{ item }">
          <price-value-dropdown :item="item" />
        </template>

        <template v-slot:item.action="{ item }">
          <div v-if="isTariff" @click="removeItem(item)" class="text-center">
            <i v-if="isParentView" class="fa fa-times" aria-hidden="true"></i>
          </div>
          <div v-else>
            <lane-action-button
              :item="item"
              @remove-item="removeItem"
            />
          </div>
        </template>
      </v-data-table>
    </v-app>
  </div>
</template>

<script>
import OriginDestinationDropdown from "@/views/common/OriginDestinationDropdown.vue";
import PriceValueDropdown from "@/views/common/PriceValueDropdown.vue";
import LaneActionButton from "@/views/common/LaneActionButton.vue";

export default {
  bodyClass: "landing",
  components: {
    OriginDestinationDropdown,
    PriceValueDropdown,
    LaneActionButton,
  },
  data: function() {
    return {
      // Component's data properties
      headers: [
        { text: "Name", value: "name", sortable: false },
        { text: "Origin", value: "origin", sortable: false },
        { text: "Destination", value: "destination", sortable: false },
        { text: "Pricing Method", value: "pricingMethod", sortable: false },
        { text: "Value", value: "value", sortable: false },
        { text: "Created On", value: "createdAt", sortable: false },
        { text: "Action", value: "action", sortable: false }
      ],
    };
  },
  props: {
    // Component props
    tableData: {
      type: Array
    },
    isTariff: {
      type: Boolean
    },
    isParentView: {
      type: Boolean
    },
  },
  methods: {
    /**
     * Remove an item from the table.
     * @param {Object} item - The item to be removed.
     */
    async removeItem(item) {
      // Emit an event to notify parent component to remove the item
      this.$emit("remove-item", item);
    },
  },
};
</script>

<style></style>
