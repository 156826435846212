<template>
  <div class="">
    <lane-form
      :isCreateLane="true"
      @reset-detail="resetDetail"
    />
  </div>
</template>

<script>
import LaneForm from "@/views/lane/LaneForm.vue";
export default {
  bodyClass: "landing",
  components: {
    LaneForm
  },
  data: function() {
    return {
    };
  },
  props: {
    
  },
  methods: {
    /**
     * Resets the detail and emits a "save-module" event.
     */
     resetDetail() {
      this.$emit("save-module");
    }
  },
};
</script>

<style></style>
