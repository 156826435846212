<template>
  <div>
    <condition-form
      :moduleView="sectionList.conditions"
      :isCreate="false"
    />
  </div>
</template>

<script>
import ConditionForm from "@/views/condition/ConditionForm.vue";
import { sectionList } from "@/helpers/utility";

export default {
  bodyClass: "landing",
  components: {
    ConditionForm,
  },
  data: function() {
    return {
      sectionList: sectionList,
    };
  },
};
</script>

<style></style>
