<template>
  <div class="tariff-app-container-height">
    <app-header
        :activeMenuName="activeMenuName"
    />
    <loader :isLoader="showLoader"></loader>
    <div class="custom-padding section-b">
      <div>
        <div class="section-title-box align-items-center d-flex">
          All Quotes
        </div>
        <div class="row view-all-section">
          <div class="col-md-3 input-v1">
            <img class="t-search-icon" src="../../assets/img/search.png">
            <vue-input
                alternative
                placeholder="Search Quotes"
                type="text"
                class="search-box"
                v-model="searchQuote"
                @input="(event) => searchQuoteByClientName(event)"
            ></vue-input>
          </div>
          <div class="col-md-3 mb-2 mb-md-0">
            <choices-single
                :options="clientList"
                v-model="selectedClient"
                @input="filterQuote"
            >
              <option value="0">Select Client</option>
            </choices-single>

          </div>
          <div class="col-md-3 mb-2 mb-md-0">
            <div class="">
              <choices-single
              >
                <option value="0">Select</option>
              </choices-single>
            </div>
          </div>
          <div class="col-md-3 mb-2 mb-md-0">
            <date-picker
                placeholder="Select Date Range"
                class="tariff-date-picker"
                format="MMM DD, YYYY"
                v-model="selectedDates"
                @input="filterQuote"
                :disabled-date="(date) => date >= new Date()"
                range
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="view-section-tables">
        <v-app id="inspire">
          <v-data-table
              v-model="selected"
              :headers="headers"
              :items="quotesList"
              :single-select="singleSelect"
              :hide-default-footer="true"
              item-key="name"
              select-enable
              class="elevation-1"
              :items-per-page="quotesList.length"
              :mobile-breakpoint="0"
          >
            <template v-slot:[`header.select`]="{ }">
              <div class="custom-control custom-checkbox">
                <input
                    id="selectAll"
                    type="checkbox"
                    class="custom-control-input"
                    v-model="selectAll"
                    @click="selectAllItems"
                />
                <label for="selectAll" class="custom-control-label"></label>
              </div>
            </template>

            <template v-slot:item.select="{ item }">
              <div class="checkbox-pin">
                <div class="custom-control custom-checkbox">
                  <input
                      :id="item.id"
                      type="checkbox"
                      v-model="item.select"
                      class="custom-control-input single-checkbox"
                  />
                  <label :for="item.id" class="custom-control-label"></label>
                </div>
              </div>
            </template>

            <template v-slot:item.createdAt="{ item }">
              <date-format
                  :date="item.createdAt"
              />
            </template>
          </v-data-table>
        </v-app>
      </div>
    </div>
  </div>
</template>

<script>
import ChoicesSingle from '@/components/SingleSelect';
import AppHeader from '@/layout/AppHeader.vue';
import Loader from '@/views/common/Loader.vue';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import DateFormat from '@/views/common/DateFormat.vue';
import 'vue2-datepicker/index.css';
import {API, masterAPI} from '@/api/API';

export default {
  name: 'light-table',
  components: {
    AppHeader,
    ChoicesSingle,
    Loader,
    DatePicker,
    DateFormat,
  },
  data() {
    return {
      activeMenuName: 'view-all-quotes',
      // Data properties
      searchQuote: '',
      selectedClient: null,
      singleSelect: false,
      selected: [],
      headers: [
        {text: 'Select', value: 'select', sortable: false},
        {text: 'Client', value: 'client', sortable: false},
        {text: 'Origin', value: 'origin', sortable: false},
        {text: 'Destination', value: 'destination', sortable: false},
        {text: 'Revenue', value: 'revenue', sortable: false},
        {text: 'Created on', value: 'createdAt', sortable: false},
      ],
      quotesList: [],
      quotesOldList: [],
      date: moment().format('MMM DD, YYYY'),
      selectedDates: [],
      showLoader: false,
      selectedQuoteId: null,
      selectAll: false,
    };
  },
  computed: {
    clientList() {
      const uniqueClients = Array.from(new Set(this.quotesList.map(item => item.client)));
      return uniqueClients.map((client, index) => ({
        id: index,
        text: client,
      }));
    },
  },
  methods: {
    /**
     * Fetches all quotes from the list and initializes data properties.
     */
    async getAllQuotes() {
      let response = await masterAPI(
          API.API_ENDPOINT.quotes,
          API.API_METHOD.get,
      );
      if (response.status === 200) {
        this.quotesList = response.data;
        this.quotesOldList = response.data;
      }
    },

    /**
     * Handle selection of all rows
     */
    selectAllItems() {
      this.selectAll = !this.selectAll;
      this.quotesList.forEach(item => {
        item.select = this.selectAll;
      });
      let checkboxElement = document.querySelectorAll('.single-checkbox');
      checkboxElement.forEach(item => {
        item.checked = this.selectAll;
      });
    },

    /**
     * Filters the quote list based on the search input.
     * @param {string} searchValue - The search value entered by the user.
     */
    searchQuoteByClientName(searchValue) {
      let searchQuoteItem = this.quotesOldList.map((filterItem) => {
        if (filterItem.client.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) {
          return filterItem;
        }
      }).filter(function(element) {
        return element !== undefined;
      });
      this.quotesList = searchQuoteItem;
    },

    /**
     * Filters the quotes list based on selected client and date values.
     */
    filterQuote() {
      let filteredArray = this.quotesOldList;
      // Filter by selected client
      if (this.selectedClient) {
        filteredArray = filteredArray.filter((quoteObj) => {
          return quoteObj.client === this.selectedClient;
        });
      }

      // Filter by selected dates
      if (this.selectedDates.length > 0 && this.selectedDates[0]) {
        const startDate = moment(this.selectedDates[0]);
        const endDate = moment(this.selectedDates[1]);
        filteredArray = filteredArray.filter((quotesObj) => {
          const quotesDate = moment(quotesObj.createdAt);
          return moment(quotesDate).isBetween(startDate, endDate, 'day', '[]');
        });
      }
      this.quotesList = filteredArray;
    },
  },
  created() {
    this.getAllQuotes();
  },
};
</script>

<style>
.tariff-date-picker input {
  height: 42px;
}

.mx-calendar-panel-date:last-child {
  display: none;
}
</style>
  