<template>
    <div class="">
      <base-rate-form
        :moduleView="moduleType"
        :isCreate="true"
        @reset-detail="resetDetail"
      />
    </div>
  </template>
  
  <script>
  import BaseRateForm from "@/views/baserate/BaseRateForm.vue";
  
  export default {
    bodyClass: "landing",
    components: {
      BaseRateForm
    },
    data: function() {
      return {
        
      };
    },
    props: {
      // Component props
      moduleType: {
        type: String,
      },
    },
    methods: {
      /**
       * Resets the detail and emits a "save-module" event.
       */
      resetDetail() {
        this.$emit("save-module");
      }
    },
  };
  </script>
  
  <style></style>
  