<template>
  <div>
    <modal :show.sync="showModal">
      <slot name="close-button">
        <button
          type="button"
          class="model-close-btn"
          @click="showModal = false"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span :aria-hidden="true">×</span>
        </button>
      </slot>

      <div>
        <div v-for="(field, i) in selectedItem.dataTypeFields" :key="i">
          <div :class="i == 0 ? '' : 'field-border-top'"></div>
          <div class="mt-3">
            <span class="font-bold data-type-text">Data Type : </span>
            <span class="fs-13">{{ field.type }}</span>
          </div>
          
          <div v-for="(itemValue, index) in field.item" :key="index" class="mb-3">
            <div class="row pricing-value-header">
              <div v-for="(key, keyIndex) in Object.keys(itemValue)" :key="keyIndex" class="col-3 font-bold text-transform-capitalize">
                {{ key }}
              </div>
            </div>
            <div class="row">
              <div v-for="(key, keyIndex) in Object.keys(itemValue)" :key="keyIndex" class="col-3 fs-13">
                {{ itemValue[key] }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { dataType, unitList } from "@/helpers/utility";

export default {
  components: {
    Modal
  },
  data: function() {
    return {
      showModal: false,
      unitList: unitList,
      dataType: dataType
    };
  },
  props: {
    selectedItem: {
      type: Object
    }
  },
  methods: {
    /**
     * Opens the modal and sets the missing reasons.
     */
    openModal() {
      this.showModal = true;
    }
  }
};
</script>
<style></style>
