<template>
  <div>
    <div class="row">
      <base-checkbox
        class="mb-2 eia-base-checkbox"
        v-model="useEiaRate"
        :checked="useEiaRate"
        @input="handleUseEiaRate"
      >
        Use US EIA base rates
      </base-checkbox>
    </div>

    <div v-if="useEiaRate">
      <div class="create-section-text mb-2 mt-3">
        Select the base rate you would like to use
      </div>
      <div class="eia-base-rate-dropdown">
        <ValidationProvider
          rules="required"
          name="Base rate"
          v-slot="{ errors }"
        >
          <multiselect
            placeholder="Select"
            :multiple="false"
            v-model="baseEiaRate"
            :options="eiaBaseRatesList"
            :close-on-select="true"
            :clear-on-select="true"
            label="name"
            track-by="name"
            @input="selectBaseRate"
          >
            <template slot="option" slot-scope="{ option }">
              <div class="multiselect__option">
                <input
                  type="checkbox"
                  :checked="baseEiaRate && baseEiaRate.key == option.key"
                  class="checkbox-right"
                />
                <span>{{ option.name }}</span>
              </div>
            </template>
          </multiselect>
          <span class="validate-error" style="display: block;">{{
              errors[0]
            }}</span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { getEiaBaseRatesList } from "@/helpers/utility";

export default {
  components: {
    Multiselect
  },
  data: function() {
    return {
      useEiaRate: false,
      baseEiaRate: null,
      eiaBaseRatesList: [],
    };
  },
  methods: {
    /**
     * Handler for selecting a base rate.
     * @param {Object} baseEiaRate - The selected base EIA rate.
    */
    selectBaseRate(baseEiaRate) {
      this.baseEiaRate = baseEiaRate;
    },
    /**
     * Handler for toggling the use of EIA rates.
     * @param {boolean} value - The new value of 'useEiaRate'.
    */
    handleUseEiaRate(value) {
      this.$emit("update-use-eia-rate", value);
      if (!value) {
        this.baseEiaRate = null;
      }
    },
    /**
     * Fetches the list of EIA base rates.
    */
    async getEiaBaseRates() {
      this.eiaBaseRatesList = await getEiaBaseRatesList();
    }
  },
  created() {
    this.getEiaBaseRates();
  }
};
</script>
